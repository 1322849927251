import CommonActions from './common_actions'

export const initPagesWithData = CommonActions.initPagesWithData
export const getNodeKey = CommonActions.getNodeKey

const INIT   = 'manual_pages_users_views/INIT'
const UPDATE   = 'manual_pages_users_views/UPDATE'
const SEARCH = 'manual_pages_users_views/SEARCH'
const UPDATE_NODE_AND_ALL_PARENTS = 'manual_pages_users_views/UPDATE_NODE_AND_ALL_PARENTS'

export const types = { INIT, UPDATE, UPDATE_NODE_AND_ALL_PARENTS, SEARCH }

export const updateNode = (path, data) => ({ type: UPDATE, path, data })

export const updateNodeAndAllParents = (path, data) => ({ type: UPDATE_NODE_AND_ALL_PARENTS, path, data })

export const fetchTreeData = (data) => {
  return ({
    type: INIT,
    request: {
      url: '/manual-pages.json',
      method: 'get',
      filter: data.filter,
      page: data.page
    },
  })
}

export const filterBy = (value) => ({ type: SEARCH, value })
