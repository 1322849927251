import { types } from 'actions/form_builder_actions'
import { fromJS, List, Map } from 'immutable'
import { deleteItem, ElementTransporter, moveRowItem } from './helpers'

function reducer (state = {}, action) {

  const [rowIndex, cellIndex, elementPosition] = action.path || []

  switch (action.type) {
    case types.INIT:
      return state.merge(fromJS({ ...action.initProps }))
    case types.UPDATE_FORM_ATTRS:
      return state.setIn(['data', 'attrs'], action.attrs)
    case types.MOVE_ROW_ITEM:
      return moveRowItem(state, action)
    case types.MOVE_ELEMENT_ITEM:
      return (new ElementTransporter(state)).move(action)//moveElementItem(state, action)
    case types.ADD_ROW_ITEM:
      const data = state.getIn(['data', 'rows'])
      return state.setIn(['data', 'rows'], data.push(fromJS(action.row)))
    case types.UPDATE_ROW_ITEM:
      return state.setIn(['data', 'rows'].concat(action.path), fromJS(action.updatedRow))
    case types.UPDATE_CELL_ITEM:
      return state.setIn(['data', 'rows', rowIndex, 'cells', cellIndex], fromJS(action.updatedCell))
    case types.UPDATE_ELEMENT_ITEM:
      return state.setIn(['data', 'rows', rowIndex, 'cells', cellIndex, 'elements', elementPosition],
        fromJS(action.updatedElement)
      )
    case types.DELETE_ROW_ITEM:
      return deleteItem(state, ['data', 'rows', action.pos])
    case types.DELETE_CELL_ITEM:
      return deleteItem(state, ['data', 'rows', rowIndex, 'cells', cellIndex])
    case types.DELETE_ELEMENT_ITEM:
      return deleteItem(state, ['data', 'rows', rowIndex, 'cells', cellIndex, 'elements', elementPosition])
    case types.SAVE:
      if (action.data?.rows) { return state.set('data', fromJS(action.data)) }
      return state
  }
  return state
}

const historyReducer = (state, action) => {
  const handleUndo = () => {
    const newPresentState = state.get('past').last()
    const futureState  = List([state.get('present')]).concat(state.get('future'))
    return Map({ past: state.get('past').pop(), present: newPresentState, future: futureState })
  }

  const handleRedo = () => {
    const newPresentState = state.get('future').first()
    const futureState = state.get('future').delete(0)
    const pastState = state.get('past').concat(List([state.get('present')]))
    return Map({ past: pastState, present: newPresentState, future: futureState })
  }

  const handleOther = (resetHistory = false) => {
    const presentState = reducer(state.get('present'), action)
    if (resetHistory) {
      return Map({ past: List([]), present: presentState, future: List([]) })
    }

    const pastState = (state.get('present').isEmpty() ? List([]) : state.get('past').push(state.get('present')))
    const futureState = List([])
    return Map({ past: pastState, present: presentState, future: futureState })
  }

  switch (action.type) {
    case types.UNDO:
      return handleUndo()
    case types.REDO:
      return handleRedo()
    default:
      return handleOther(action.type == types.SAVE)
  }
}

export default historyReducer
