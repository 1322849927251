export const camelCase = (str) => {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export const snakeCase  = (str) => str.replace(/[A-Z]/g, val => "_" + val.toLowerCase()).replace(/^_/,"")

export const makeObjectKeysAsSnakeCase = (obj) => Object.keys(obj).reduce((result, key) => {
  result[snakeCase(key)] = obj[key]
  return result
}, {})

export const nestedKeysAsSnakeCase = (obj) => Object.keys(obj).reduce((result, key) => {
  const objByKey = obj[key]
  if ((objByKey instanceof Object) && !Array.isArray(objByKey)) {
    result[snakeCase(key)] = nestedKeysAsSnakeCase(objByKey)
  }
  else { result[snakeCase(key)] = objByKey }
  return result
}, {})

// example { 'group[name]': 'Sample of group', 'group[workflow_id]': 100 }
// will be converted to { group: { name: 'Sample of group', workflow_id: 100 } }
export const boxBracketKeyToNestedObjectKey = (obj) => {
  return Object.keys(obj).reduce((result, key) => {
    const matchedKeys = key.match(/(.*)\[(.*)\]/)
    if (matchedKeys && matchedKeys.length == 3) {
      const [_, rootObjKey, childObjKey] = matchedKeys
      result[rootObjKey] = Object.assign({}, result[rootObjKey] || {}, { [childObjKey]: obj[key] })
    }
    else { result[key] = obj[key] }

    return result
  }, {})
}

export const prepareFilesDataForSending = (files) => {
  return files.map(file => {
    const fileData = { id: (parseInt(file.id) ? file.id : ''), position: file.position }

    if (fileData.id) { fileData._destroy = file._destroy }
    else { fileData.temp_file = file.src }

    return fileData
  })
}
