const INIT                 = 'multi_step/workflow/overview-board/INIT'
const SELECT_FILTER_OPTION = 'multi_step/workflow/overview-board/SELECT_FILTER_OPTION'

export const types = {
  INIT, SELECT_FILTER_OPTION
}

export const initOverviewData = (data) => ({ type: INIT, data })

export const selectFilterOption = (filterType, opts) => ({ type: SELECT_FILTER_OPTION, filterType, opts})
