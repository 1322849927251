import { types } from 'actions/manual_pages/users_view_actions'
import { fromJS, List, Map } from 'immutable'
import MenuFilter, {
  updateFoundRecordsIncludeParents, updateItemsByPath, updateItemByPath
} from './search'

const updateStateOnInit = (state, { data, filter, page }) => {
  if (filter) {
    return (new MenuFilter(filter)).getNewFilteredState(state, data.treeData)
  }

  if (page) {
    updateItemsByPath(data.treeData, page.path.concat(page.id), { subMenuState: 'expanded' })
  }

  return state.set('treeData', fromJS(data.treeData)).set('page', fromJS(page))
}

const updateNodeAndAllParents = (state, { path, data }) => {
  let treeData = state.get('treeData').toJS()
  treeData = updateItemsByPath(treeData, path, data)
  return state.set('treeData', fromJS(treeData))
}

const updateItem = (state, { path, data }) => {
  let treeData = state.get('treeData').toJS()
  treeData = updateItemByPath(treeData, path, data)
  return state.set('treeData', fromJS(treeData))
}

function reducer (state, action) {

  switch (action.type) {
    case types.INIT:
      return  updateStateOnInit(state, action)
    case types.UPDATE:
      return updateItem(state, action)
    case types.UPDATE_NODE_AND_ALL_PARENTS:
      return updateNodeAndAllParents(state, action)
    case types.SEARCH:
      return (new MenuFilter(action.value)).getNewFilteredState(state)
  }

  return state
}

export default reducer
