import { EventEmitter } from 'fbemitter'

class PhotoswipeStore extends EventEmitter {
  constructor () {
    super()
    this.items = {}
    this.isOpen = false
    this._currPswpGallery = null
  }

  set currPswpGallery (pswp) {
    window.pswp = pswp
    this._currPswpGallery = pswp
  }

  get currPswpGallery () {
    return this._currPswpGallery
  }

  get currentItem () {
    return this.currPswpGallery && this.currPswpGallery.currItem
  }

  addImage (image) {
    const galleryId = image.galleryId || 'default'
    if (this.items[galleryId] === undefined) { this.items[galleryId] = [] }

    const width = image.w || 0
    const height = image.h || 0

    const item = {
      src: image.src,
      position: image.position,
      w: width,
      h:  height,
      title: image.title,
      albumTitle: image.albumTitle,
      destroyUrl: image.destroyUrl,
      rotateUrl: image.rotateUrl,
      editUrl: image.editUrl,
      updatePositionUrl: image.updatePositionUrl,
      downloadUrl: image.downloadUrl,
      uploadedAt: image.uploadedAt
    }

    if (this.items[galleryId].indexOf(item) < 0) {
      this.items[galleryId].push(item)
      this.items[galleryId].sort((a,b) => a.position - b.position)
    }

    return item
  }

  removeImage(item, galleryId = 'default') {
    if (this.items[galleryId] === undefined) { return }

    const index = this.items[galleryId].indexOf(item)
    if (index < 0) { return }

    this.items[galleryId].splice(index, 1)
  }

  closePhotoswipe () {
    this.isOpen = false
    this.update()
  }

  openPhotoswipe (item, galleryId) {
    this.galleryId = galleryId || 'default'
    this.index = this.items[this.galleryId].indexOf(item)
    this.isOpen = true
    this.update()
  }

  get galleryItems () {
    if (!this.galleryId) return []
    return this.items[this.galleryId]
  }

  set galleryItems (items) {
    if (this.galleryId) {
      this.items[this.galleryId] = items
      this.currPswpGallery.items.splice(0, items.length, ...items)
      this.currPswpGallery.invalidateCurrItems()
      this.currPswpGallery.updateSize(true)
      this.currPswpGallery.ui.update()
    }
    else { throw('galleryId was not specified') }
  }

  isEnabledRotateControls () {
    return _.some(this.galleryItems, (i) => i.rotateUrl )
  }

  isEnabledPositionUpdate () {
    return _.some(this.galleryItems, (i) => i.updatePositionUrl )
  }

  rotateCurrentItem (degree, direction) {
    let item = this.currPswpGallery.currItem
    if (!item || !item.rotateUrl) return null
    this.currPswpGallery.showProgressBar()

    if (direction == 0) degree = degree * (-1)
    return ajax({ url: item.rotateUrl,
      method: 'patch',
      data: { degree: degree, gallery_id: this.galleryId }
    }).then(function (resp) {
      const data = { src: resp.data.src, w: resp.data.w, h: resp.data.h }
      let items = this.galleryItems
      const index = items.indexOf(item)
      items[index] = Object.assign(item, data)

      this.currPswpGallery.updateCurrentItem(data)
    }.bind(this))
  }

  updatePosition (url, pos) {
    return ajax({url: url, method: 'patch', data: { position: pos }})
  }

  update () {
    this.emit('change')
  }

  pswpChanged () {
    this.emit('PswpChanged')
  }

}

export default new PhotoswipeStore()
