const INIT_BOXES       = 'campaigns/location_selector/INIT_BOXES'
const SELECT_ITEM      = 'campaigns/location_selector/SELECT_ITEM'
const SELECT_ALL       = 'campaigns/location_selector/SELECT_ALL'
const REMOVE_ITEM      = 'campaigns/location_selector/REMOVE_ITEM'
const REMOVE_ALL       = 'campaigns/location_selector/REMOVE_ALL'
const SAVE_SELECTED    = 'campaigns/location_selector/SAVE_SELECTED'
const NEXT_STEP        = 'campaigns/location_selector/NEXT_STEP'
const STEP_BACK        = 'campaigns/location_selector/STEP_BACK'
const FILTER_BY        = 'campaigns/location_selector/FILTER_BY'
const FILTER_BY_TAGS   = 'campaigns/location_selector/FILTER_BY_TAGS'

export const types = { INIT_BOXES, SELECT_ITEM, SELECT_ALL, REMOVE_ITEM, REMOVE_ALL, SAVE_SELECTED, FILTER_BY, FILTER_BY_TAGS, NEXT_STEP, STEP_BACK }
export const initBoxes = (data) => ({ type: INIT_BOXES, ...data })
export const selectItem = (itemId) => ({ type: SELECT_ITEM, itemId })
export const selectAll = () => ({ type: SELECT_ALL })
export const removeItem = (itemId) => ({ type: REMOVE_ITEM, itemId })
export const removeAll = () => ({ type: REMOVE_ALL })
export const filterBy = (value) => ({ type: FILTER_BY, value })
export const filterByTags = (tags) => ({ type: FILTER_BY_TAGS, tags })
export const saveSelected = (campaignId, locationIDs, tagIDs, stepAction, next) => ({
  type: SAVE_SELECTED,
  request: {
    url: `/campaigns/${campaignId}`,
    method: 'put',
    body: {
      location_ids: locationIDs,
      location_tag_ids: tagIDs,
      step: 'locations',
      step_action: stepAction,
      next: next,
      format: 'json'
    },
    redirectTo: true
  }
})

export const nextStep = (campaignId) => ({
  type: NEXT_STEP,
  request: {
    url: `/campaigns/${campaignId}`,
    method: 'put',
    body: {
      step: 'locations',
      next: true,
      format: 'json'
    },
    redirectTo: true
  }
})

export const stepBack = (campaignId) => ({
  type: STEP_BACK,
  request: {
    url: `/campaigns/${campaignId}`,
    method: 'put',
    body: {
      step: 'edit',
      back: true,
      format: 'json'
    },
    redirectTo: true
  }
})
