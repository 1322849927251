import CommonActions from './common_actions'
import { snakeCase, prepareFilesDataForSending } from 'libs/helpers'

export const getNodeKey = CommonActions.getNodeKey

const CHANGE_POSITION = 'manual_pages/CHANGE_POSITION'
const UPDATE_TREE_ITEMS = 'manual_pages/UPDATE_TREE_ITEMS'

const INIT   = 'manual_pages/INIT'
const NEW    = 'manual_pages/NEW'
const CREATE = 'manual_pages/CREATE'
const EDIT   = 'manual_pages/EDIT'
const UPDATE = 'manual_pages/UPDATE'
const DELETE = 'manual_pages/DELETE'
const SOFT_UPDATE = 'manual_pages/SOFT_UPDATE'

export const types = { INIT, UPDATE_TREE_ITEMS, CHANGE_POSITION, NEW, CREATE, SOFT_UPDATE, UPDATE, DELETE, EDIT }

export const initPagesWithData = (initProps) => ({ type: INIT, ...initProps })

export const updateTree = (treeData) => ({ type: UPDATE_TREE_ITEMS, treeData })

export const newPage = (page) => ({ type: NEW, page })

const prepareDataForSending = (data) => {
  const bodyData = Object.keys(data).reduce((result, attr) => {
    if (attr == 'id') { return result }

    if (['documents', 'photos', 'videos'].includes(attr)) {
      result[`${attr}_attributes`] = prepareFilesDataForSending(data[attr])
    }
    else { result[snakeCase(attr)] = data[attr] }

    return result
  }, {})

  return [data.id, bodyData]
}

export const createPage = (data) => {

  const [_, pageData] = prepareDataForSending(data)

  return ({
    type: CREATE,
    request: {
      url: ``,
      method: 'post',
      body: { page: pageData }
    }
  })
}

export const editPage = (id) => {
  return ({
    type: EDIT,
    request: {
      url: ` /manual-pages/admin/pages/${id}/edit`,
      method: 'get'
    }
  })
}

export const updatePage = (data) => (dispatch, getState) => {
  const [id, pageData] = prepareDataForSending(data)
  dispatch({ type: SOFT_UPDATE, id, data })
  dispatch({
    type: UPDATE,
    request: {
      url: `/manual-pages/admin/pages/${id}`,
      method: 'patch',
      body: { page: pageData }
    }
  })
}

export const deletePage = (id) => {

  return ({
    type: DELETE,
    request: {
      url: `/manual-pages/admin/pages/${id}`,
      method: 'delete',
      body: {},
      id: id
    }
  })
}

export const changePosition = (id, position, parent_id) => {
  const data = { position, parent_id }

  return ({
    type: CHANGE_POSITION,
    request: {
      url: `/manual-pages/admin/pages/${id}/change-position`,
      method: 'patch',
      body: data
    }
  })
}

export const TREE_ACTIONS = { updateTree, changePosition }
