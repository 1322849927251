import { types } from 'actions/sharing_links_actions'
import { fromJS } from 'immutable'

function addLink(links, link) {
  const idx = links.findIndex(l => l.get('id') === link.id)
  if (idx === -1) {
    return links.push(fromJS(link))
  }
  return links.set(idx, fromJS(link))
}

function removeLink(links, id) {
  const idx = links.findIndex(l => l.get('id') === id)
  return links.delete(idx)
}

export default function reducer(state, action) {
  switch (action.type) {
    case types.TOGGLE_DIALOG:
      return state.set('dialogOpened', action.opened)
        .set('currentLink', action.currentLink)
        .set('successMessage', null)
        .set('errorMessage', null)
    case types.LOAD_SHARING_LINKS:
      return state.set('links', fromJS(action.links))
    case `${types.SAVE_SHARING_LINK}_FAILURE`:
      if (action.error && action.error.response && action.error.response.data && action.error.response.data.errors) {
        return state.set('errorMessage', action.error.response.data.errors.join(', '))
      } else {
        return state.set('errorMessage', action.error.message)
      }
    case types.SAVE_SHARING_LINK:
      return state
        .set('currentLink', action.data.sharing_link)
        .set('successMessage', action.data.message)
        .set('links', addLink(state.get('links'), action.data.sharing_link))
    case `${types.DELETE_SHARING_LINK}_FAILURE`:
      return state.set('errorMessage', action.error.message)
    case types.DELETE_SHARING_LINK:
      return state
        .set('dialogOpened', false)
        .set('links', removeLink(state.get('links'), action.data.sharing_link.id))
    default:
      return state
  }
}
