import { types } from 'actions/condition'
import { fromJS, Map } from 'immutable'

const SIBLINGS = {
  right: 'left',
  left: 'right',
}

const DEFAULT_FIELDS = {
  group: { type: 'group' },
  condition: { type: 'condition', condition: { table: '' } },
}

export default function reducer (state = new Map(), action, defaultFields = DEFAULT_FIELDS) {
  switch (action.type) {
    case types.REMOVE_CONDITION_TYPE:
      const last = action.path.last()
      const siblingKey = SIBLINGS[last]
      if (siblingKey) {
        const sibling = state.getIn(action.path.pop().push(siblingKey))
        return state.setIn(action.path.pop(), sibling)
      } else {
        return state.deleteIn(action.path)
      }
    case types.ADD_CONDITION_TYPE:
      const currentContent = state.getIn(action.path.push('content'))
      let newValue = null
      if (currentContent) {
        newValue = { type: 'and', left: currentContent, right: defaultFields[action.conditionType] }
      } else {
        newValue = defaultFields[action.conditionType]
      }
      return state.setIn(action.path.push('content'), fromJS(newValue))
    case types.UPDATE_CONDITION_TYPE:
      return state.mergeIn(action.path, action.attributes)
  }
  return state
}
