import { Component } from 'react'

const MIN_BUTTON_HEIGHT = 78
const BUTTON_RATIO = 216 / 150

export default class AlbumUploadButton extends Component {
  componentDidMount () {
    this.$button = $(this.refs.button)
    this.$nearestPhoto = this.$button.closest('.gallery').find('a.photo:last img')

    this.$nearestPhoto.on('load', this.updateRatio)
    $(window).on('resize', this.updateButtonSize)

    this.updateRatio()
  }
  componentWillUnmount () {
    this.$nearestPhoto.off('load', this.updateRatio)
    $(window).off('resize', this.updateButtonSize)
    this.$button = null
    this.$nearestPhoto = null
  }

  updateRatio = () => {
    this.ratio = this.$nearestPhoto ? this.$nearestPhoto.outerWidth() / this.$nearestPhoto.outerHeight() : BUTTON_RATIO
    this.updateButtonSize()
  }

  updateButtonSize = () => {
    const newHeight = Math.max((this.$button.width() / this.ratio), MIN_BUTTON_HEIGHT)
    this.$button.height(newHeight)
  }

  render () {
    return (
      <a ref="button" className={this.props.className} id={this.props.id} />
    )
  }
}
