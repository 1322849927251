import { types } from 'actions/project_actions'
import { Map } from 'immutable'
import conditions from './conditions'
import columns from './columns'

const handleId = (state = null, action) => {
  if (action.type === action.LOAD_REPORT) { return action.report.id }
  return state
}

export default function (state = Map({}), action) {
  return Map({
    id: handleId(state.get('id'), action),
    columns: columns(state.get('columns'), action),
    conditions: conditions(state.get('conditions'), action),
  })
}
