import React from 'react'
import FavoriteControl from './favorite_control'
import PhotoswipeLink from 'components/shared/photoswipe/photoswipe_link'

const Photo = ({favorite, src, title, url, thumbUrl, w, h,
  destroyUrl, rotateUrl, editUrl, albumTitle, galleryId, date, makeFavorite}) => {

  return (
    <div className="gallery-photo-wrapper">
      <div className="card card-inverse gallery-photo">
        <FavoriteControl {...favorite} makeFavorite={makeFavorite} />
        <img className="card-img-top" src={thumbUrl} alt={title} />
        <PhotoswipeLink className="photo card-img-overlay gallery-photo-overlay"
          src={src || url} w={w} h={h}
          destroyUrl={destroyUrl}
          rotateUrl={rotateUrl}
          editUrl={editUrl}
          albumTitle={albumTitle}
          galleryId={galleryId || albumTitle}
          uploadedAt={date}>
        </PhotoswipeLink>
        <div className="card-title gallery-photo-title">
          {title}
          &nbsp;
          <small className="uploaded-at">{date}</small>
        </div>
      </div>
    </div>
  )
}

export default Photo
