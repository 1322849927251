const INIT = 'form_builder/INIT'
const SAVE = 'form_builder/SAVE'
const UNDO = 'form_builder/UNDO'
const REDO = 'form_builder/REDO'

const UPDATE_FORM_ATTRS = 'form_builder/UPDATE_FORM_ATTRS'

const ADD_ROW_ITEM = 'form_builder/ADD_ROW_ITEM'
const DELETE_ROW_ITEM = 'form_builder/DELETE_ROW_ITEM'
const UPDATE_ROW_ITEM = 'form_builder/UPDATE_ROW_ITEM'
const MOVE_ROW_ITEM = 'form_builder/MOVE_ROW_ITEM'

const MOVE_CELL_ITEM   = 'form_builder/MOVE_CELL_ITEM'
const UPDATE_CELL_ITEM = 'form_builder/UPDATE_CELL_ITEM'
const DELETE_CELL_ITEM = 'form_builder/DELETE_CELL_ITEM'

const MOVE_ELEMENT_ITEM = 'form_builder/MOVE_ELEMENT_ITEM'
const DELETE_ELEMENT_ITEM = 'form_builder/DELETE_ELEMENT_ITEM'
const UPDATE_ELEMENT_ITEM = 'form_builder/UPDATE_ELEMENT_ITEM'

export const types = { INIT, SAVE, UNDO, REDO, UPDATE_FORM_ATTRS,
  ADD_ROW_ITEM, DELETE_ROW_ITEM, UPDATE_ROW_ITEM, MOVE_ROW_ITEM,
  MOVE_CELL_ITEM, UPDATE_CELL_ITEM, DELETE_CELL_ITEM,
  MOVE_ELEMENT_ITEM, DELETE_ELEMENT_ITEM, UPDATE_ELEMENT_ITEM
}

export const updateFormAttrs = (attrs) => ({ type: UPDATE_FORM_ATTRS, attrs })
export const initForm = (initProps) => ({ type: INIT, initProps })
export const saveForm = (url, data) => {
  const method = (/\/\d+$/.test(url) ? 'patch' : 'post')
  return {
    type: SAVE,
    request: {
      url: `${url}.json`,
      method: method,
      body: { handover_form: data },
      redirectTo: true
    }
  }
}
export const undoChange = () => ({ type: UNDO })
export const redoChange = () => ({ type: REDO })

// ROWS
export const moveRowItem = (oldPos, newPos) => ({ type: MOVE_ROW_ITEM, oldPos, newPos })
export const updateRowItem = (updatedRow, path) => ({ type: UPDATE_ROW_ITEM, updatedRow, path })
export const addRowItem = (row) => ({ type: ADD_ROW_ITEM, row })
export const deleteRowItem = (pos) => ({ type: DELETE_ROW_ITEM, pos })

// CELLS
export const deleteCellItem = (path) => ({ type: DELETE_CELL_ITEM, path})
export const updateCellItem = (updatedCell, path) => ({ type: UPDATE_CELL_ITEM, updatedCell, path })

// ELEMENTS
export const deleteElementItem = (path) => ({ type: DELETE_ELEMENT_ITEM, path })
export const updateElementItem = (updatedElement, path)  => ({ type: UPDATE_ELEMENT_ITEM, updatedElement, path })
export const moveElementItem   = (fromPath, toPath)  => ({ type: MOVE_ELEMENT_ITEM, fromPath, toPath })
