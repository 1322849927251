import { Map } from 'immutable'
import mspStepButton from './msp_step_button'
import formReducer from './form_reducer'
import sendLinksButtonReducer from './send_links_button'
import bulkAssignInstallers from './bulk_assign_installers'

function reducer (state, action) {
  return Map({
    mspData: mspStepButton(state.get('mspData'), action),
    form: formReducer(state.get('form'), action),
    send_links: sendLinksButtonReducer(state.get('send_links'), action),
    bulk_assign_installers: bulkAssignInstallers(state.get('bulk_assign_installers'), action)
  })
}

export default reducer
