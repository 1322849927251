import { types } from 'actions/uploader_actions'
import { fromJS, List, Map } from 'immutable'

const updateFileData = (state, buttonId, id, data) => {
  const fileIndex = state.getIn([buttonId, 'files']).findIndex(file => file.get('id') == id)
  return state.mergeIn([buttonId, 'files', fileIndex], fromJS(data))
}

const updateFavoriteAttrs = (state, buttonId, id, attrs) => {
  const fileIndex = state.getIn([buttonId, 'files']).findIndex(file => file.get('id') == id)
  return state.mergeIn([buttonId, 'files', fileIndex, 'favorite'], fromJS(attrs))
}

const addNewFiles = (state, buttonId, files) => {
  const presentFiles = state.getIn([buttonId, 'files'])
  return state.setIn([buttonId, 'files'], presentFiles.concat(fromJS(files)))
}

const reorderFiles = (state, buttonId, ids) => {
  const path = [buttonId, 'files']
  let files = state.getIn(path)

  let position = 1
  ids.forEach((id) => {
    const idx = files.findIndex(file => file.get('id').toString() === id)
    files = files.setIn([idx, 'position'], position)
    position += 1
  })

  return state.setIn(path, files.sortBy(f => f.get('position')))
}

const filesUploaded = (state, action) => {
  const { buttonId, data } = action
  return state.setIn([buttonId, 'files'], fromJS(data.attachments))
}

const buildPath = (state, buttonId, id) => {
  if (!buttonId) { return [] }
  if (!id) { return [] }

  const path = [buttonId, 'files']
  const index = buttonId ? state.getIn(path).findIndex(item => item.get('id') == id) : null
  if (index != null) { path.push(index) }

  return path
}

const moveFilesBetweenUploaders = (state, sourceKey, targetKey, ids) => {
  const filesByGroups = state.getIn([sourceKey, 'files']).toJS().reduce((result, file) => {
    const updatedFile = { ...file, seleted: false }
    if (ids.includes(file.id)) { result.moved.push(updatedFile) } else { result.rest.push(updatedFile) }
    return result
  }, { moved: [], rest: [] })

  const sourceFiles = fromJS(filesByGroups.rest)
  const targetFiles = state.getIn([targetKey, 'files']).concat(fromJS(filesByGroups.moved))
  return state.setIn([sourceKey, 'files'], sourceFiles).setIn([targetKey, 'files'], targetFiles)
}

export default function reducer (state = {}, action) {
  const {
    buttonId, data, files, id,
  } = action
  const path = buildPath(state, buttonId, id)

  switch (action.type) {
    case types.INIT:
      return state.set(buttonId, fromJS(data))
    case types.INIT_ALBUMS:
      return state.merge(fromJS(action.uploaders))
    case types.SET_UPLOADER_STATE:
      return state.setIn([buttonId, 'state'], action.state)
    case types.ADD_NEW_FILES:
      return addNewFiles(state, buttonId, files)
    case types.UPDATE:
      return state.mergeIn(path, fromJS(data).merge({ _updated: true }))
    case types.REORDER:
      return reorderFiles(state, buttonId, action.ids)
    case types.UPDATE_FILE_DATA:
      return updateFileData(state, buttonId, data.id, { src: data.url, _new: true })
    case types.AUTOSAVE_ATTACHMENTS:
      return filesUploaded(state, action)
    case types.MAKE_FAVORITE:
      return updateFavoriteAttrs(state, buttonId, id, { enabled: action.favorite })
    case types.REMOVE_FROM_STATE:
      return state.deleteIn(path)
    case types.DESTROY_ATTACHMENT:
      return state.deleteIn(path)
    case types.MARK_FOR_DESTROY:
      return state.mergeIn(path, fromJS({ _destroy: true }))
    case types.UPDATE_ATTACHABLE:
      return moveFilesBetweenUploaders(state, action.sourceKey, action.targetKey, action.data.moved_file_ids)
    default:
      return state
  }
}
