const LOAD_PROJECTS = 'projects/send_links/LOAD_PROJECTS'

export const types = {
  LOAD_PROJECTS,
}

export const loadProjects = (url, ids) => ({
  type: LOAD_PROJECTS,
  request: {
    url,
    method: 'get',
    body: { format: 'json', ids },
  },
})
