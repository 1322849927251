import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import Spinner from 'components/shared/spinner'
import MODAL_STYLES from 'components/shared/modal_styles'
import ImageList from './image_list'

const I18n = window.I18n

const SelectControl = (props) => {
  return <a id={props.id}>{I18n.t('application.actions.select')}</a>
}

export default class ThumbnailsCollection extends Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
    buttonId: PropTypes.string.isRequired,
    dropElementId: PropTypes.string.isRequired,
    isUploading: PropTypes.bool,
  }

  render () {
    const { dropElementId, buttonId, files, isUploading, fileActions } = this.props

    return (
      <div id={dropElementId} className="thumbnails-collection">
        {files.length === 0 && <SelectControl id={buttonId} />}
        <ImageList images={files} {...fileActions} />
        <ReactModal ariaHideApp={false} isOpen={!!isUploading} style={MODAL_STYLES}>
          <Spinner />
          {I18n.t('uploader.uploading')}
        </ReactModal>
      </div>
    )
  }
}
