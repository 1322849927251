import { useEffect } from 'react'
// your component must define a ref named "dropzone"
// class "drag" will be added to it when user starts dragging something over the
// page

const Modernizr  = window

class HighlightDropzoneBehavior {
  constructor (container_id) {
    this.container_id = container_id
  }
  bindDragEvents = () => {
    if (!Modernizr || !Modernizr.draganddrop) { return }
    const dropzone = this._$dropzone()
    $('html').on('dragover', this._drageventNoop)
    $('html').on('dragleave', this._drageventNoop)
    $('body').on('dragbetterenter', this._addDropzoneHighlight)
    $('body').on('dragbetterleave', this._removeDropzoneHighlight)
    dropzone.on('dragover', this._addDropzoneHighlight)
    dropzone.on('drop', this._removeDropzoneHighlight)
  }
  unbindDragEvents = () => {
    if (!Modernizr || !Modernizr.draganddrop) { return }
    const dropzone = this._$dropzone()
    $('html').off('dragover', this._drageventNoop)
    $('html').off('dragleave', this._drageventNoop)
    $('body').off('dragbetterenter', this._addDropzoneHighlight)
    $('body').off('dragbetterleave', this._removeDropzoneHighlight)
    dropzone.off('dragover', this._addDropzoneHighlight)
    dropzone.off('drop', this._removeDropzoneHighlight)
  }
  _$dropzone = () => $(`#${this.container_id}`)
  _drageventNoop = (e) => e.preventDefault()
  _addDropzoneHighlight = () => this._$dropzone().addClass('drag')
  _removeDropzoneHighlight = () => this._$dropzone().removeClass('drag')
}

const DropZone = ({ buttonId, dropElementId, uploadButtonCaption, inputDescription }) => {
  if (!inputDescription) { inputDescription = I18n.t('uploader.to_attach_fiels_drop_them_here_or') }

  const dropzone = new HighlightDropzoneBehavior(dropElementId)

  useEffect(() => {
    dropzone.bindDragEvents()
    return () => dropzone.unbindDragEvents()
  }, [])

  return (
    <>
      { !uploadButtonCaption && <span dangerouslySetInnerHTML={{ __html: inputDescription }} /> }
      {" "}
      <a id={buttonId} className="upload-button">
        {uploadButtonCaption || I18n.t('uploader.click_here_to_choose_files')}
      </a>
    </>
  )
}

export default DropZone
