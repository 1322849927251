import { fromJS } from 'immutable'
import { types } from 'actions/shared'

export default function reducer (state, action) {
  switch (action.type) {
    case types.SET_UPLOADER_OPTIONS:
      return fromJS(action.data)
  }
  return state
}
