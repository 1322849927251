import { stringify } from 'query-string'

const SET_AVAILABLE_FILTERS = 'reports/projects_report/SET_AVAILABLE_FILTERS'
const SET_SAVED_FILTERS = 'reports/projects_report/SET_SAVED_FILTERS'
const SET_SELECTED_FILTERS = 'reports/projects_report/SET_SELECTED_FILTERS'
const LOAD_AVAILABLE_FILTERS = 'reports/projects_report/LOAD_AVAILABLE_FILTERS'
const UPDATE_USER_FILTERS = 'reports/projects_report/UPDATE_USER_FILTERS'

export const types = {
  SET_AVAILABLE_FILTERS,
  SET_SELECTED_FILTERS,
  LOAD_AVAILABLE_FILTERS,
  SET_SAVED_FILTERS,
  UPDATE_USER_FILTERS,
}

export const setAvailableFilters = (filters) => ({
  type: SET_AVAILABLE_FILTERS,
  filters,
})

export const setSavedFilters = (filters) => ({
  type: SET_SAVED_FILTERS,
  filters,
})

export const setSelectedFilters = (filters) => ({
  type: SET_SELECTED_FILTERS,
  filters,
})

export const loadAvailableFilters = (data) => {
  // prepend each key with 'filters' to match the API params
  const filters = Object.entries(data).reduce((acc, [key, value]) => {
    acc[`filters[${key}]`] = value
    return acc
  }, {})
  const qParams = stringify(filters, { arrayFormat: 'bracket' })

  return {
    type: LOAD_AVAILABLE_FILTERS,
    request: {
      url: `/additional-reports/projects_filters.json?${qParams}`,
      method: 'get',
    },
  }
}

export const updateUserFilters = (data) => ({
  type: UPDATE_USER_FILTERS,
  request: {
    url: '/additional-reports/update_user_filters.json',
    method: 'post',
    body: { filters: data },
  },
})
