import { fromJS, List, Map } from 'immutable'
import { types } from 'actions/projects/msp_step_button_actions'

function reducer (state, action) {
  switch (action.type) {
    case types.INIT:
      return state.set('projects', fromJS(action.projects))
        .set('steps', fromJS(action.steps))
        .set('current', fromJS(action.current))
        .set('workflows', fromJS(action.workflows))
    case types.CREATE_MSP_PROJECT:
      return state.set('projects', fromJS(action.data.projects))
    case types.LOAD_MSP_PROJECT_STEPS:
      return state.set('steps', fromJS(action.data.steps))
  }
  return state
}

export default reducer
