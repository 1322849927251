import { types } from 'actions/admin_template_fields'
import { fromJS, Map } from 'immutable'
import { orderBy, mapValues } from 'lodash'

const addFieldsReducer = (state, names) => {
  const fieldBuilder = (result, fieldData) => {
    return result.setIn(['fields', fieldData.value], fromJS({
      caption: fieldData.label,
      default_value: '',
      label_override: '',
      editable: true,
      position: state.get('fields').size
    }))
  }

  const fields = names.map((f) => f.value )
  const newMasterList = state.get('masterList').filter((item) => {
    return fields.indexOf(item.get('value')) == -1
  })

  return names.reduce(fieldBuilder, state).set('masterList', newMasterList)
}

const removeFieldReducer = (state, name) => {
  const newMasterList = state.get('masterList').push(fromJS({
    value: name, label: state.getIn(['fieldsOptions', name, 'caption'])
  }))
  return state.deleteIn(['fields', name]).set('masterList', newMasterList)
}

const changeFieldDataReducer = (state, fieldName, attrKey, value) => {
  return state.setIn(['fields', fieldName, attrKey], value)
}

const moveFieldReducer = (state, name, position) => {
  const fields = state.get('fields').sortBy((f) => f.get('position'))
  let fieldsNames = fields.keySeq().toList()
  const idx = fieldsNames.findIndex(kname => kname == name)
  fieldsNames = fieldsNames.delete(idx)
  fieldsNames = fieldsNames.splice(position, 0, name);
  const updatedFields = fieldsNames.reduce((result, fName, index) => {
    let item = fields.get(fName);
    result = result.set(fName, item.set("position", index))
    return result
  }, Map())

  return state.set('fields', updatedFields.sortBy((f) => f.get('position')))
}

const initialUpdatePositions = (fields) => {
  const mapped = mapValues(fields, (v, k) => {return { key: k, position: v.position}})
  const sorted = orderBy(mapped, 'position')
  sorted.forEach((f, idx) => {
    fields[f.key].position = idx
  })

  return fields
}

export default function reducer (state, action) {
  switch (action.type) {
    case types.INIT_FIELDS:
      const { masterList, fieldsOptions, fields, translations } = action
      return fromJS({ masterList, fieldsOptions, fields: initialUpdatePositions(fields), translations })
    case types.ADD_FIELDS:
      return addFieldsReducer(state, action.fieldNames)
    case types.REMOVE_FIELD:
      return removeFieldReducer(state, action.fieldName)
    case types.CHANGE_FIELD_DATA:
      return changeFieldDataReducer(state, action.fieldName, action.attrKey, action.value)
    case types.MOVE_FIELD:
      return moveFieldReducer(state, action.fieldName, action.position)
  }
  return state
}
