export default class CompositElementID {

  static createFrom (list) {
    list.map(item => new CompositElementID(...item))
  }

  constructor (id, elementID, type, index) {
    this._id = id
    this._elementID = elementID
    this._type = type
    this._index = index
  }

  key = (prefix = 'key') => `${prefix}_${this._id}-${this._elementID}-${this._index}`

  isEqual = (cID) => {
    return this._id == cID._id && this._elementID == cID._elementID && this._index == cID._index
  }
}
