const AlbumName = ({ id, headerClass, title }) => {
  return (
    <div>
      <a name={`album-${id}`} />
      <h4 className={headerClass} id={`album_${id}`}>{title }</h4>
    </div>
  )
}

export default AlbumName
