import { fromJS, Map } from 'immutable'
import { types } from '../../actions/campaigns/summary_actions'

export default function reducer(state = Map({}), action) {
  switch (action.type) {
    case types.SET_HIDDEN_FIELDS:
      const hiddenFields = state.get('hiddenFields')
      const index = hiddenFields.findIndex(el => el.get('elementId') === action.hiddenFields.elementId)

      if (index !== -1) {
        return state.updateIn(['hiddenFields', index, 'totalPrints'], () => action.hiddenFields.totalPrints)
      } else {
        const newElement = fromJS({ elementId: action.hiddenFields.elementId, totalPrints: action.hiddenFields.totalPrints})
        return state.update('hiddenFields', hiddenFields => hiddenFields.push(newElement))
      }

    default:
      return state
  }
}
