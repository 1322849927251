import { types } from 'actions/report'
import { fromJS, Map, List } from 'immutable'
import _ from 'underscore'

export default function reducer (state = new Map(), action) {
  switch (action.type) {
    case types.LOAD_REPORT:
      return _.size(action.report.columns) ? fromJS(action.report.columns) : new List()
    case types.ADD_COLUMN:
      return state.push(fromJS(action.attributes))
    case types.REMOVE_COLUMN:
      return state.delete(action.index)
    case types.UPDATE_COLUMN:
      return state.mergeIn([action.index], action.attributes)
    case types.CHANGE_POSITION:
      const firstColumn = state.get(action.startIndex)
      const secondColumn = state.get(action.endIndex)
      return state.set(action.endIndex, firstColumn).set(action.startIndex, secondColumn)
  }
  return state
}
