import PhotoswipeLink from 'components/shared/photoswipe/photoswipe_link'

const ATTRIBUTES_FOR_ICON = ['documents']

export default ({ attribute, file }) => {
    if (ATTRIBUTES_FOR_ICON.includes(attribute)) {
      return (<span className="fa fa-picture-o" />)
    }

    // const name = this.props.editTitle ? null : this.props.file.name
    const { href, src, name, editUrl, rotateUrl, downloadUrl } = file

    return (
      <PhotoswipeLink
        src={href || src}
        galleryId="preview-images"
        title={name}
        editUrl={editUrl}
        rotateUrl={rotateUrl}
        downloadUrl={downloadUrl}
      >
        <img src={src} alt={name} />
        <span className="image-name">{name}</span>
      </PhotoswipeLink>
    )
  }
