import React from 'react'

class HiddenInputs extends React.Component {

  getItems () {
    const { items, defaultEmptyValue } = this.props
    return items.length ? items : defaultEmptyValue
  }

  render () {


    return (
      <div className='hidden-inputs-container'>
        { this.getItems().map((item, index) =>
            <input
              type="hidden"
              name={this.props.name + '[]'}
              value={ item.value }
              key= { index }
            />
          )
        }
      </div>
    )
  }
}

HiddenInputs.defaultProps = {
  defaultEmptyValue: [{ value: '' }]
}

export default HiddenInputs
