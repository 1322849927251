import { prepareFilesDataForSending } from 'libs/helpers'
import CompositElementID from './composit_element_id'
import _ from 'underscore'

export default class CampaignElementItem {

  static createList(list) {
    return list.map(elAttrs => new CampaignElementItem(elAttrs))
  }

  static reInitCopy(item) {
    const newItem = new CampaignElementItem({})
    Object.keys(item).forEach(key => {
      if (key[0] == '_') {
        if (Array.isArray(item[key]))   { newItem[key] = Array.from(item[key]) }
        else if (_.isObject(item[key])) { newItem[key] = Object.assign({}, item[key]) }
        else                            { newItem[key] = item[key] }
      }
    })
    return newItem
  }

  constructor(attrs, ancestor = null) {
    this._campaignElementID = attrs.campaign_element_id
    this._name = attrs.name
    this._locationName = attrs.location_name
    this._type = attrs.type
    this._minAmount  = attrs.min_amount || ''
    this._maxAmount  = attrs.max_amount || ''
    this._note = attrs.note || ''
    this._dimensions = attrs.dimensions
    this._elementID  = attrs.value
    this._label = attrs.label
    this._images = attrs.images || []
    this._tags = attrs.tags || []
    this._matchedTags = []
    this._tagIds = attrs.tag_ids || []
    this._tagsAmounts = attrs.tags_amounts || {}
    this._selected = !!attrs.selected
    this._prefix = attrs.prefix
    this._ancestor = ancestor

    this._destroy = false

    if (!ancestor) { this._descendants = [] }
    else {
      ancestor._descendants.push(this)
      this._index = ancestor._descendants.length
    }
  }

  get ancestor () { return this._ancestor || this }

  get name () { return this._name }

  get locationName () { return this._locationName }

  get campaignElementID () { return this._campaignElementID }
  get elementID () { return this._elementID }

  get minAmount () { return this._minAmount }
  get maxAmount () { return this._maxAmount }

  get dimensions () { return this._dimensions }

  get note () { return this._note }

  // campaign element tags
  get tags () { return this._tags }
  set tags (newSetOfTags) { return this._tags = newSetOfTags }

  // own element tagIds
  get tagIDs () { return this._tagIds }

  // matched tags: own element tags which crossed with selected tags
  get matchedTags () { return this._matchedTags}
  set matchedTags (tags) { return this._matchedTags = tags }

  get tagsAmounts () { return this._tagsAmounts }

  get isSelected () { return this._selected }
  get isCopy () { return !!this._ancestor }
  get isMarkedForDestory () { return this._destroy }
  get isCommon () { return this._type == 'Elements::CommonElement' }
  get isLocation () { return this._type == 'Elements::LocationElement' }
  get isCampaign () { return this._type == 'Elements::CampaignElement' }

  get cID () { return (new CompositElementID(this._campaignElementID, this._elementID, this._type, this._index)) }

  get images () { return this._images.filter(img => !img._destroy) }

  get descendantsNum () { return this.ancestor._descendants.length }

  get prefix () { return this._prefix || '' }

  key = (prefix) => this.cID.key(prefix)

  prepareDataForSend = () => {
    return {
      id: this._campaignElementID,
      element_id: this._elementID,
      min_amount: this._minAmount,
      max_amount: this._maxAmount,
      name: this._name,
      note: this._note,
      photos_attributes: prepareFilesDataForSending(this._images),
      tag_ids: this._tags.map(t => t.value),
      tags_amounts: this.tagsAmounts,
      _destroy: this.isMarkedForDestory
    }
  }

  calcPositionForCopy = (sourceItemIndexInList) => {
    const selfIndex = this._index || 0
    return sourceItemIndexInList + (this.descendantsNum - selfIndex)
  }

  positionIn = (iList) => {
    return iList.findIndex(item => item.cID.isEqual(this.cID))
  }

  makeFree = () => {
    // this mean it is actual only for children(copied objects)
    if (!this.ancestor) { return }

    // remove from descendants
    this.ancestor._descendants.splice(this._index - 1, 1)
    // recalc index
    this.ancestor._descendants.forEach((item, i) => {
      item._index = i + 1
    })
  }

  deleteFrom = (iList, startPos, removeNum) => {
    return iList.splice(startPos, removeNum)
  }

  // remove from descendants and remove from state elements
  deleteChild = (iList) => {
    this.makeFree()
    return this.deleteFrom(iList, this.positionIn(iList), 1)
  }

  // remove from descendants and from state elements
  deleteChildren = (iList) => {
    const removeNum = this.descendantsNum
    if (removeNum) { this.ancestor._descendants = [] }
    return this.deleteFrom(iList, this.positionIn(iList) + 1, removeNum)
  }

  clone = () => {
    const copyAttrs = Object.getOwnPropertyNames(this).reduce((result, attr) => {
      result[attr.substring(1)] = this[attr]
      return result
    }, {})

    const copy = new CampaignElementItem({}, this.ancestor)
    copy.update(copyAttrs)
    return copy
  }

  selectorAttrs = () => {
    return {
      campaignElementID: this._campaignElementID,
      value: this._elementID,
      label: this._label,
      name: this._name,
      type: this._type,
      prefix: this.prefix
    }
  }

  update = (attrs) => {
    const EXCEPT_ATTRS = ['campaignElementID', 'ancestor', 'index']

    Object.getOwnPropertyNames(attrs).filter(attr => {
      return !EXCEPT_ATTRS.includes(attr)
    }).forEach(attr => {
      if (attr == 'selected' && this._campaignElementID) { this._assignDestroy(attrs[attr]) }

      if (attr == 'images' && attrs[attr].length > 0) {
        this[`_images`] = attrs[attr].map(img => Object.assign({}, img))
      }
      else if (Array.isArray(attrs[attr])) { this[`_${attr}`] = Array.from(attrs[attr]) }
      else { this[`_${attr}`] = attrs[attr] }
    })
  }

  _assignDestroy = (isSelected) => {
    if (isSelected) { this._destroy = false }
    else { this._destroy = true }
  }
}
