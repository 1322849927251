const SELECT        = 'overview/SELECT'
const INIT          = 'overview/INIT'
const GET_PROJECTS  = 'overview/GET_PROJECTS'

export const types = { SELECT, INIT, GET_PROJECTS }

export const init = data => ({
  type: INIT,
  request: {
    url: '/overview-info',
    method: 'get',
    body: {
      project_group_ids: data.projectGroupIds,
      msp_workflow_ids: data.mspWorkflowIds
    },
  },
})

export const select = (type, newIds, data) => ({
  type: SELECT,
  request: {
    url: `/overview-info/select/${type}`,
    method: 'get',
    body: {
      new_selected_ids: newIds,
      project_group_ids: data.projectGroupIds,
      msp_workflow_ids: data.mspWorkflowIds
    },
  },
})

export const getProjects = (data) => ({
  type: GET_PROJECTS,
  request: {
    url: '/overview-info/projects',
    method: 'get',
    body: data
  }
})
