import { fromJS } from 'immutable'
import { getNodeKey } from 'actions/manual_pages/users_view_actions'

export const updateItemsByPath = (treeData, path, data) => {
  while (path.length) {
    updateItemByPath(treeData, Array.from(path), data)
    path.pop()
  }
  return treeData
}

export const updateItemByPath = (treeData, path, data) => {
  const node = findNodeByPath(treeData, path)
  if (node) {
    if (typeof(data) === 'function') { Object.assign(node, data(node)) }
    else { Object.assign(node, data) }
  }
  return treeData
}

export const findNodeByPath = (branch, path) => {
  const id = path.splice(0, 1)[0]
  const node = branch.find(item => item.id == id )
  if (path[0]) { return findNodeByPath(node.children, path) }
  else { return node }
}

export default class MenuFilter {
  constructor (value) {
    this._searchValue    = value
    this._suitableData   = (node) => {
      let subMenuState = null
      if (node.children.length) {
        subMenuState = (value ? 'expanded' : 'collapsed')
      }
      return Object.assign({}, node, { suitable: true, subMenuState })
    }
    this._unsuitableData = (node) => {
      let subMenuState = null
      if (node.children.length) { subMenuState = 'collapsed' }
      return Object.assign({}, node, { suitable: false, subMenuState })
    }
  }

  getNewFilteredState = (state, initTreeData = null) => {
    const treeData = initTreeData || state.get('treeData').toJS()
    let updatedTreeData = this._resetPreviousFilterChanges(treeData)
    const collectedPaths = []
    updatedTreeData = this._handleItems(updatedTreeData, [], collectedPaths)

    collectedPaths.forEach((path) => updateItemsByPath(updatedTreeData, path, this._suitableData))

    return state.set('treeData', fromJS(updatedTreeData)).set('filter', this._searchValue)
  }

  _resetPreviousFilterChanges = (items) => {
    return items.map(item => {
      item = Object.assign({}, this._unsuitableData(item))
      if (item.children.length) {
        item.children = this._resetPreviousFilterChanges(item.children)
      }
      return item
    })
  }

  _handleItems = (items, currentPath, collectedPaths) => {
    return items.map(item => {
      if (this._isSuitableItem(item)) {
        if (currentPath.length) { collectedPaths.push(Array.from(currentPath)) }
        item = Object.assign({}, this._suitableData(item))
      }
      if (item.children.length) {
        const itemPath = Array.from(currentPath).concat(item.id)
        item.children = this._handleItems(item.children, itemPath, collectedPaths)
      }
      return item
    })
  }

  _isSuitableItem = (node) => (node.title.search(new RegExp(this._searchValue, 'gi')) != -1)
}
