import axios from 'axios'
import _ from 'underscore'
import queryString from 'query-string'

const apiMiddleware = store => next => (action) => {
  if (!action.request) { return next(action) }

  const BASE_TYPE = action.type
  const REQUEST   = `${action.type}_REQUEST`
  const SUCCESS   = `${action.type}_SUCCESS`
  const FAILURE   = `${action.type}_FAILURE`

  next({ type: REQUEST, ...(_.omit(action, ['type', 'request'])) })

  const queryParams = action.request.method === 'get' && action.request.body ? `?${queryString.stringify(action.request.body, { arrayFormat: 'bracket' })}` : ''
  return axios[action.request.method](`${action.request.url}${queryParams}`, action.request.body, action.request.options || {})
    .then(({ data }) => {
      next({ type: SUCCESS, data })
      return next({ type: BASE_TYPE, data, ...(_.omit(action.request, ['body', 'url', 'method', 'options'])) })
    })
    .catch(error => next({ type: FAILURE, error }))
}

export default apiMiddleware
