import { useMemo, useCallback } from 'react'
import classNames from 'classnames'

const FavoriteControl = ({id, enabled, onClassName, offClassName, makeFavorite}) => {
    if (!id) { return null }

    const className = useMemo(() => enabled ? onClassName : offClassName, [enabled, onClassName, offClassName])
    const onClick = useCallback(() => makeFavorite(id, !enabled), [id, enabled])

    return (
        <div className="state-indicator">
            <i className={classNames(['favorite', className])} aria-hidden="true" onClick={onClick} />
        </div>
    )
}

export default FavoriteControl
