import React from 'react'
import store from 'stores/photoswipe_store'
import PreviewImageItem from '../../preview_image_item'

class PhotoswipeLink extends React.Component {

  constructor (props) {
    super (props)
    this.item = store.addImage(this.props)
  }

  componentWillUnmount () {
    store.removeImage(this.item, this.props.galleryId)
  }

  handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    store.openPhotoswipe(this.item, this.props.galleryId)
  }

  render () {
    return (
      <PreviewImageItem preview={this.props.preview}
        className={this.props.className}
        style={this.props.style}
        src={this.props.src}
        onClick={this.handleClick}
      >
        {this.props.children}
      </PreviewImageItem>
    )
  }
}

export default PhotoswipeLink
