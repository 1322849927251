import { EventEmitter } from 'fbemitter'
import ajax from 'axios'

class AttachmentsCategoriesStore extends EventEmitter {

  constructor () {
    super()
    this.options = null
    this.busy = false
    this.url = null

    this.callback = function (resp) {
        this.options = resp.data.options
        this.busy = false
        this.update()
        return resp.data
    }.bind(this)
  }

  init (url) {
    this.url = url
    if (!this.options && !this.busy) {
      this.busy = true
      ajax.get(this.url).then(this.callback)
    }
  }

  create (name) {
    return ajax({url: this.url, method: 'post', data: { name: name } }).then(this.callback)
  }

  update () {
    this.emit('OptionListUpdated')
  }
}

export default new AttachmentsCategoriesStore()
