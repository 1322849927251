import pageChangesStore from 'stores/page_changes_store'

const apiMiddleware = store => next => action => {
  const prevState = store.getState()
  const result = next(action)

  if (action.skipChangesMonitor) { return result }

  pageChangesStore.reviewReduxStateChanges(action.type, prevState, store.getState())

  return result
}

export default apiMiddleware
