const LOAD_REPORT = 'report/LOAD_REPORT'
const ADD_COLUMN = 'report/ADD_COLUMN'
const REMOVE_COLUMN = 'report/REMOVE_COLUMN'
const UPDATE_COLUMN = 'report/UPDATE_COLUMN'
const CHANGE_POSITION = 'report/CHANGE_POSITION'

export const types = { LOAD_REPORT, ADD_COLUMN, REMOVE_COLUMN, UPDATE_COLUMN, CHANGE_POSITION }

export const loadReport = (report, dictionaries) => ({ type: LOAD_REPORT, report, dictionaries })

export const addColumn = attributes => ({ type: ADD_COLUMN, attributes })
export const removeColumn = index => ({ type: REMOVE_COLUMN, index })
export const updateColumn = (index, attributes) => ({ type: UPDATE_COLUMN, index, attributes })
export const changePosition = (startIndex, endIndex) => ({ type: CHANGE_POSITION, startIndex, endIndex })
