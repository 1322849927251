import _ from 'underscore'
import { EventEmitter } from 'fbemitter'

const $ = window.$

class JqMonitor {
  constructor (pageInspector, mainContSelector) {
    this._pageInspector = pageInspector
    this._$mainCont = $(mainContSelector)
    this._changes = {}
    this._inputsChanges = {}
    this.subscribe()
  }

  isChanged = () => !!Object.keys(this._changes).length

  subscribe = () => {
    $(this._$mainCont).on('change', 'input:not([type=text]), select', (e) => {
      this.trackChange(e.currentTarget)
    })
    $(this._$mainCont).on('focus', 'input[type=text], textarea', (e) => {
      this._inputsChanges[e.currentTarget] = $(e.currentTarget).val()
    })
    $(this._$mainCont).on('keyup', 'input[type=text], textarea', (e) => {
      if (this._inputsChanges[e.currentTarget] != $(e.currentTarget).val()) {
        this.trackChange(e.currentTarget)
      }
    })
  }

  trackChange = (target) => {
    if (this._changes[target]) { this._changes[target] += 1 }
    else { this._changes[target] = 1 }
    this._pageInspector.emitChanged()
  }
}

class ReduxStateMonitor {
  constructor (pageInspector, keys, options) {
    this._pageInspector = pageInspector
    this._keys = keys
    this._except = options.except || []
    this._only   = options.only   || []
    this._changes = 0
  }

  isChanged = () => !!this._changes

  review = (type, prevState, state) => {
    if (this._except.includes(type)) { return }

    if (this._only.includes(type) || this.stateChanged(prevState, state)) {
      this._changes += 1
      this._pageInspector.emitChanged()
    }
  }

  stateChanged = (prevState, state) => !(prevState.getIn(this._keys).equals(state.getIn(this._keys)))

}

class PageChangesStore extends EventEmitter {
  constructor (pageId) {
    super()
    this._pageId = pageId
    this._jqMonitors = []
    this._reduxStateMinotors = []
  }

  addJqMonitor = (selector) => {
    this._jqMonitors.push(new JqMonitor(this, selector))
  }

  addReduxStateMonitor = (keys, options = {}) => {
    this._reduxStateMinotors.push(new ReduxStateMonitor(this, keys, options))
  }

  reviewReduxStateChanges = (type, prevState, state) => {
    this._reduxStateMinotors.forEach(monit => monit.review(type, prevState, state))
  }

  isChanged = () => {
    retrun (
      this._jqMonitors.find(monit => monit.isChanged())
      ||
      this._reduxStateMinotors.find(monit => monit.isChanged())
    )
  }

  emitChanged = () => {
    this.emit('changed')
  }
}

export default (new PageChangesStore(window.location.href))
