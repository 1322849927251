import { types as reportTypes } from 'actions/report'
import { types as tagTypes } from 'actions/tag'
import { Map } from 'immutable'
import { createSelector } from 'reselect'

export default function reducer (state = new Map({}), action) {
  switch (action.type) {
    case reportTypes.LOAD_REPORT:
    case tagTypes.LOAD_TAG:
      return state.merge({ ...action.dictionaries })
  }
  return state
}


export const getTable = createSelector(
  (table, models) => ({ table, models }),
  ({ table, models }) => models.find(m => m.get('table') === table),
)

export const getField = createSelector(
  (table, field) => ({ table, field }),
  ({ table, field }) => table.getIn(['fields']).find(m => m.get('field') === field),
)

export const selectors = {
  getTable,
  getField,
}
