export const ASSIGN_INSTALLERS = 'project_tickets/ASSIGN_INSTALLERS'
export const ASSIGN_INSTALLERS_REQUEST = `${ASSIGN_INSTALLERS}_REQUEST`
export const ASSIGN_INSTALLERS_SUCCESS = `${ASSIGN_INSTALLERS}_SUCCESS`
export const ASSIGN_INSTALLERS_FAILURE = `${ASSIGN_INSTALLERS}_FAILURE`

export const assignInstallers = (projectTicketIds, installerIds) => {
  return {
    type: ASSIGN_INSTALLERS,
    request: {
      url: `/project_tickets/${projectTicketIds[0]}/bulk_assign_commit`,
      method: 'patch',
      body: {
        project_ticket_ids: projectTicketIds,
        installer_ids: installerIds,
      },
      options: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  }
}
