const INIT = 'projects/msp/step-button/INIT'
const CREATE_MSP_PROJECT = 'projects/msp/step-button/CREATE_MSP_PROJECT'
const LOAD_MSP_PROJECT_STEPS = 'projects/msp/step-button/LOAD_MSP_PROJECT_STEPS'
const ASSIGN_STEP = 'projects/msp/step-button/ASSIGN_STEP'

export const types = {
  INIT, CREATE_MSP_PROJECT, LOAD_MSP_PROJECT_STEPS
}

export const init = (data) => ({ type: INIT, ...data })

export const createMspProject = (projectId, data) => ({
  type: CREATE_MSP_PROJECT,
  request: {
    url: '/multi_step/projects',
    method: 'post',
    body: { format: 'json', project_id: projectId, multi_step_project: data }
  }
})

export const assignStep = (projectId, mspProjectId, workflowStepId) => ({
  type: ASSIGN_STEP,
  request: {
    url: `/project_tickets/${projectId}/assign_step`,
    method: 'patch',
    body: { msp_project_id: mspProjectId, workflow_step_id: workflowStepId },
    redirectTo: true
  }
})

export const loadStepsForMspProject = (mspProjectId) => ({
  type: LOAD_MSP_PROJECT_STEPS,
  request: {
    url: `/multi_step/projects/${mspProjectId}/steps`,
    method: 'get',
    body: { format: 'json' },
  }
})
