import { types } from 'actions/project_actions'
import { Map } from 'immutable'
import transient from './transient'
import group from './group/index'

export default function reducer (state = new Map(), action) {
  return Map({
    group: group(state.get('group'), action),
    transient: transient(state.get('transient'), action),
  })
}
