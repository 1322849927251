class ValueStore {
  constructor (currentVal, options = {}) {
    this.value = this.initValue(currentVal)
    this.options = options || {}
  }

  valueForInput () {
    return this.value
  }
}

class SingleValueStore extends ValueStore {

  initValue (val) { return this.setValue(val || {}) }

  isMulti () { return false }

  setValue (data) {
    this.value = data
    return data
  }
}

class MultiValueStore extends ValueStore {
  initValue (val) { return this.setValue(val || []) }

  isMulti () { return true }

  setValue (data) {
    this.value = data
    return data
  }
}

class JoinValuesStore extends MultiValueStore {

  valueForInput () {
    return _.map(this.value, (obj) => obj.value ).join(',')
  }
}

const STORE_VARIANTS = {
  'single': SingleValueStore,
  'multi': MultiValueStore,
  'join': JoinValuesStore
}

export default function (storeKey) {
  return STORE_VARIANTS[storeKey]
}
