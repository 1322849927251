const LOAD_DATA = 'rent_list/LOAD_DATA'
const RENT_ELEMENT = 'rent_list/RENT_ELEMENT'
const RENT_ELEMENT_REQUEST = `${RENT_ELEMENT}_REQUEST`
const RENT_ELEMENT_FAILURE = `${RENT_ELEMENT}_FAILURE`
const UNRENT_ITEM = 'rent_list/UNRENT_ITEM'
const UNRENT_ITEM_REQUEST = `${UNRENT_ITEM}_REQUEST`
const UNRENT_ITEM_FAILURE = `${UNRENT_ITEM}_FAILURE`
const CLEAR_ERRORS = 'rent_list/CLEAR_ERRORS'

export const types = {
  LOAD_DATA,
  RENT_ELEMENT,
  RENT_ELEMENT_REQUEST,
  RENT_ELEMENT_FAILURE,
  UNRENT_ITEM,
  UNRENT_ITEM_REQUEST,
  UNRENT_ITEM_FAILURE,
  CLEAR_ERRORS,
}

export const loadData = data => ({ type: LOAD_DATA, data })
export const clearErrors = data => ({ type: CLEAR_ERRORS, data })
export const rentElement = (rentListId, elementId) => ({
  type: RENT_ELEMENT,
  request: {
    url: `/elements_rent_lists/${rentListId}/rent_element.json`,
    method: 'post',
    body: {
      element_id: elementId,
    },
  },
})
export const unrentItem = (rentListId, rentItemId) => ({
  type: UNRENT_ITEM,
  request: {
    url: `/elements_rent_lists/${rentListId}/unrent_item.json`,
    method: 'post',
    body: {
      rent_item_id: rentItemId,
    },
  },
})
