import { makeObjectKeysAsSnakeCase } from 'libs/helpers'

const INIT    = 'uploader_v2/INIT'
const REORDER = 'uploader_v2/REORDER'
const UPDATE  = 'uploader_v2/UPDATE'
const SET_UPLOADER_STATE = 'uploader_v2/SET_UPLOADER_STATE'

const REMOVE_FROM_STATE  = 'uploader_v2/REMOVE_FROM_STATE'
const DESTROY_ATTACHMENT = 'uploader_v2/SERVER_DESTROY'
const MARK_FOR_DESTROY   = 'uploader_v2/MARK_FOR_DESTORY'

const ADD_NEW_FILES    = 'uploader_v2/ADD_NEW_FILES'

const UPDATE_FILE_DATA = 'uploader_v2/UPDATE_FILE_DATA'
const MAKE_FAVORITE    = 'uploader_v2/MAKE_FAVORITE'
const AUTOSAVE_ATTACHMENTS = 'uploader_v2/AUTOSAVE_ATTACHMENTS'

const UPDATE_ATTACHABLE = 'uploader_v2/UPDATE_ATTACHABLE'

const INIT_ALBUMS  = 'uploader_v2/INIT_ALBUMS'
const CREATE_ALBUM = 'uploader_v2/CREATE_ALBUM'

export const types = {
  INIT, REORDER, UPDATE, ADD_NEW_FILES, SET_UPLOADER_STATE,
  UPDATE_FILE_DATA, AUTOSAVE_ATTACHMENTS,
  REMOVE_FROM_STATE, DESTROY_ATTACHMENT, MARK_FOR_DESTROY,
  UPDATE_ATTACHABLE, MAKE_FAVORITE,
  INIT_ALBUMS, CREATE_ALBUM
}

// uploader states
const READY_UPLOADER = 'ready'
const UPLOADING_UPLOADER = 'uploading'
export const setUploaderState = (buttonId, { state, isReady, isUploading }) => ({
  type: SET_UPLOADER_STATE,
  buttonId,
  state: state || (isReady && READY_UPLOADER) || (isUploading && UPLOADING_UPLOADER) || 'not set'
})

export const initUploader = (buttonId, data) => ({ type: INIT, buttonId, data })

export const initAlbums = (uploaders) => ({ type: INIT_ALBUMS, uploaders })

export const removeFile = (buttonId, id) => (dispatch, getState) => {
  const path = ['uploader', buttonId]
  const files = getState().getIn(path.concat('files'))
  const file  = files.find(item => item.get('id') == id).toJS()

  if (file._new) {
    dispatch({ type: REMOVE_FROM_STATE, buttonId, id })
  }
  else if (getState().getIn(path).get('autosave')) {
    dispatch(destroyAttachment(buttonId, id))
  }
  else {
    dispatch({ type: MARK_FOR_DESTROY, buttonId, id })
  }

  return true
}

const destroyAttachment = (buttonId, id) => {

  return ({
    type: DESTROY_ATTACHMENT,
    request: {
      url: `/attachments/${id}`,
      method: 'delete',
      body: { id },
      buttonId, id
    }
  })
}

export const createLocationAlbum = (id, name) => {
  return ({
    type: CREATE_ALBUM,
    request: {
      url: `/locations/${id}/create_album`,
      method: 'post',
      body: { album: { title: name } },
      redirectTo: true
    }
  })
}

export const updateFile = (buttonId, id, data) => ({ type: UPDATE, buttonId, id, data })

export const reorderFiles = (buttonId, ids) => ({type: REORDER, buttonId, ids})

export const addNewFiles = (buttonId, files) => (dispatch, getState) => {
  dispatch(setUploaderState(buttonId, { state: UPLOADING_UPLOADER }))
  dispatch({ type: ADD_NEW_FILES, buttonId, files })
}

export const makeFavorite = (buttonId, id, favorite) => ({ 
  type: MAKE_FAVORITE, buttonId, id,
  request: {
    url: `/album_photos/${id}/favorite`,
    method: 'patch',
    body: { album_photo: { favorite } },
    buttonId, id, favorite
  }
})

export const fileUploaded = (buttonId, data, summaryInfo) => (dispatch, getState) => {
  const isLastFile = (summaryInfo.percent == 100 || summaryInfo.queued === 0)
  dispatch(updateFileData(buttonId, data))
  dispatch(setUploaderState(buttonId, { isReady: isLastFile }))
  const uploaderState = getState().getIn(['uploader', buttonId]).toJS()
  if (uploaderState.autosave && isLastFile) {
    dispatch(autosaveAttachments(buttonId, uploaderState.modelData, uploaderState.files))
  }
}
export const updateFileData = (buttonId, data) => ({ type: UPDATE_FILE_DATA, buttonId, data })
const autosaveAttachments = (buttonId, modelData, files) => {

  const data = {
    meta: { 
      model_id: modelData.id, 
      model_name: modelData.name, 
      model_attribute: modelData.attribute,
      model_serializer: modelData.serializer
    },
    attributes: files.reduce((result, file) => {
      if (file._new) { result.push({ temp_file: file.src }) }
      return result
    }, [])
  }

  return ({
    type: AUTOSAVE_ATTACHMENTS,
    request: {
      url: '/attachments/autosave.json',
      method: 'put',
      body: { attachment: data },
      buttonId
    }
  })
}

export const updateAttachable = (sourceKey, targetKey) => (dispatch, getState) => {

  const ids  = getState().getIn(['uploader', sourceKey, 'files']).toJS().reduce((result, file) => {
    if (file.selected) { result.push(file.id) }
    return result
  }, [])

  if (ids.length === 0) { return }

  const sourceModelData = getState().getIn(['uploader', sourceKey, 'modelData']).toJS()
  const targetModelData = getState().getIn(['uploader', targetKey, 'modelData']).toJS()

  const data = {
    ids: ids,
    source: makeObjectKeysAsSnakeCase(sourceModelData),
    target: makeObjectKeysAsSnakeCase(targetModelData)
  }

  dispatch({
    type: UPDATE_ATTACHABLE,
    request: {
      url: '/attachments/update_attachable',
      method: 'patch',
      body: data,
      sourceKey, targetKey
    }
  })
}


export const pluploadWrapperActions = { addNewFiles, fileUploaded }
export const fileActions = { removeFile, updateFile, makeFavorite, reorderFiles }
