import React from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import AsyncSelect from "react-select/async"

const enhanceProps = props => ({
  ...props,
  classNamePrefix: 'Select',
  className: 'Select',
  closeMenuOnSelect: !props.isMulti,
  isDisabled: props.isDisabled,
  styles: { menu: styles => ({ ...styles, zIndex: 1000 }) },
})

const BaseReactSelect = props => {
  const  { styles } = props
  const enhancedProps = enhanceProps(props)
  if (styles) { enhancedProps.styles = {...enhancedProps.styles, ...styles} }
  return <Select {...enhancedProps} />
}

export const Creatable = props => <CreatableSelect {...enhanceProps(props)} />

export const Async = props => <AsyncSelect {...enhanceProps(props)} />

export default BaseReactSelect
