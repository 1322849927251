import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import api from '../middleware/api'
import modal from '../middleware/modal'
import redirectTo from '../middleware/redirect_to'
import pageChangesMonitor from '../middleware/page_changes_monitor'

import reducers from '../reducers'
import defaultState from './default_state'

let enhancer = null

const APP_MIDDLEWARES = [
  api, modal, redirectTo, pageChangesMonitor, thunk
]

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
  enhancer = applyMiddleware(...APP_MIDDLEWARES)
} else {
  const logger = createLogger({
    stateTransformer: (state) => state.toJS(),
    diff: true,
    collapsed: true
  })
  APP_MIDDLEWARES.push(logger)
  // Middleware you want to use in development:
  enhancer = composeWithDevTools(applyMiddleware(...APP_MIDDLEWARES))
}

const store = createStore(
  reducers,
  defaultState,
  enhancer,
)

export default store
