import React, { useEffect, useRef } from 'react'
import File from './file/index'
import SortableBehavior from '../../behaviors/sortable_behavior'

const notDeleted = file => !file._destroy
const extractTargetValue = (target) => {
  if (target.type == 'checkbox') { return target.checked }

  return target.value
}

const FileList = (props) => {
  const { files, filesOptions, modelData, buttonId, removeFile, updateFile, reorderFiles, isReqStateInProgress } = props
  const handleSorting = (ids) => { reorderFiles(buttonId, ids) }
  const me = useRef(null)
  const context = { refs: { me }, handleSorting, props }

  const sortableBehavior = new SortableBehavior(context)
  useEffect(() => { sortableBehavior.initSortable() }, [])
  useEffect(() => (() => { sortableBehavior.destroySortable() }), [])

  const handleDelete = id => removeFile(buttonId, id)
  const handleChanges = (id, e) => updateFile(buttonId, id, { [e.target.name]: extractTargetValue(e.target) })

  return (
    <div className="files-container" ref={me}>
      { files.filter(notDeleted).map(file => (
        <File
          key={file.id}
          file={file}
          options={filesOptions}
          modelData={modelData}
          onDelete={handleDelete}
          onChange={handleChanges}
          isReqStateInProgress={isReqStateInProgress}
        />
      ))}
    </div>
  )
}


export default FileList
