import { types } from 'actions/tag'
import _ from 'underscore'
import { fromJS } from 'immutable'
import conditions from '../shared/conditions'


export default function (state, action, field, defaultFields) {
  if (action.type === types.LOAD_TAG) {
    return _.size(action.tag[field]) ? fromJS(action.tag[field]) : fromJS({ type: 'group' })
  }
  return field === action.field ? conditions(state, action, defaultFields) : state
}
