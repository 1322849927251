import * as React from 'react'
import ViewSDKClient from './view_sdk_client'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const { I18n } = window as any

interface FileCollaborationToolProps {
  adobePreviewFileOptions: { userName: string; clientId: string }
  file: { presignedUrl: string; name: string; id: number }
  thumb: boolean
}

export default function FileCollaborationTool(props: FileCollaborationToolProps) {
  const { adobePreviewFileOptions, file, thumb } = props
  const userName = adobePreviewFileOptions.userName
  const clientId = adobePreviewFileOptions.clientId

  const [dialogOpened, setDialogOpened] = React.useState(false)
  const toggle = () => setDialogOpened(!dialogOpened)
  const modalContent = <div id="tool-div"></div>

  const viewSDKClient = new ViewSDKClient()

  const previewFile = () => {
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        {
          embedMode: 'FULL_WINDOW',
          enableAnnotationAPIs: true,
          includePDFAnnotations: true,
          downloadWithAnnotations: true,
          showDownloadPDF: true,
          showPrintPDF: true,
        },
        {
          content: { location: { url: file.presignedUrl } },
          metaData: { fileName: file.name, id: file.id.toString() },
        },
        clientId,
        'tool-div'
      )

      viewSDKClient.registerSaveApiHandler()
      viewSDKClient.registerGetUserProfileApiHandler(userName)
    })
  }
  const closeBtn = (
    <button
      onClick={() => {
        if (window.confirm(I18n.t('project_tickets.design_files_popup_window.confirmation'))) {
          toggle()
          window.location.reload()
        }
      }}
      className="close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  )
  return (
    <>
      {thumb && thumb === true ? (
        <a
          href=""
          onClick={(e) => {
            e.preventDefault()
            setDialogOpened(true)
            previewFile()
          }}
        >
          <span className="fa fa-file-text-o" />
        </a>
      ) : (
        <a
          href=""
          onClick={(e) => {
            e.preventDefault()
            setDialogOpened(true)
            previewFile()
          }}
        >
          {file.name}
        </a>
      )}

      <Modal isOpen={dialogOpened} toggle={toggle} className="collaboration-tool-modal">
        <ModalHeader toggle={toggle} close={closeBtn}>
          {file.name}
        </ModalHeader>
        <ModalBody>{modalContent}</ModalBody>
      </Modal>
    </>
  )
}
