import UploadButton from './upload_button'
import ReactModal from 'react-modal'
import MODAL_STYLES from 'components/shared/modal_styles'
import Spinner from 'components/shared/spinner'

const AlbumUploader = ({dropElementId, buttonId, isUploading }) => {

  return (
    <div className="gallery-photo-wrapper">
      <div className="card gallery-photo gallery-uploader">
        <div className="card-body">
          <div className="photo_uploader" id={dropElementId}>
            <UploadButton className="upload" id={buttonId} />
            <ReactModal ariaHideApp={false} isOpen={!!isUploading} style={MODAL_STYLES}>
              <Spinner />
              {I18n.t('uploader.uploading')}
            </ReactModal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlbumUploader
