import { types } from 'actions/surveys_element'
import { fromJS, Map } from 'immutable'
import _ from 'underscore'
import { SCOPE_ALL } from '../../components/admin/surveys/template_association/association_options/constants'

function maxPosition (state) {
  const fields = state.get('fields')
  if (fields.count() === 0) {
    return 0
  }

  const field = fields.reduce((a, b) => (parseInt(a.get('position'), 10) > parseInt(b.get('position'), 10) ? a : b))
  return parseInt(field.get('position'), 10)
}
function fieldIndexByPosition(state, position) {
  return state.get('fields').findIndex(f => f.get('position') == position)
}

function removeFieldReducer (state, action) {
  const idx = fieldIndexByPosition(state, action.position)
  const field = state.getIn(['fields', idx])
  const id = field.get('id')
  let newState = state.set('fields', state.get('fields').delete(fieldIndexByPosition(state, action.position)))
  if (id) {
    newState = newState.set('deletedIds', newState.get('deletedIds').push(id))
  }
  return newState
}
function changePositionReducer (state, action) {
  const startIndex = fieldIndexByPosition(state, action.startPosition)
  const endIndex = fieldIndexByPosition(state, action.endPosition)
  const startField = state.getIn(['fields', startIndex])
  const endField = state.getIn(['fields', endIndex])
  return state.setIn(['fields', startIndex], endField.set('position', action.startPosition))
    .setIn(['fields', endIndex], startField.set('position', action.endPosition))
}

function changeInputValueReducer (state, action) {
  let newState = state.setIn(['fields', fieldIndexByPosition(state, action.position), action.name], action.value)
  if (action.name === 'template_field') {
    newState = newState.deleteIn(['fields', fieldIndexByPosition(state, action.position), 'default_value'])
  }
  return newState
}


export default function reducer (state = new Map(), action) {
  switch (action.type) {
    case types.LOAD_TEMPLATES:
      return state.set('templates', fromJS(action.templates))
    case types.LOAD_FIELDS:
      return state.set('fields', fromJS(action.fields.map(f => ({ ...f, uid: _.uniqueId() }))))
    case types.LOAD_SIBLINGS:
      return state.set('siblings', fromJS(action.siblings))
    case types.CHANGE_INPUT_VALUE:
      return changeInputValueReducer(state, action)
    case types.ADD_FIELD:
      return state.set(
        'fields',
        state.get('fields')
          .push(fromJS({ ...action.params, position: maxPosition(state) + 1, uid: _.uniqueId() }))
      )
    case types.REMOVE_FIELD:
      return removeFieldReducer(state, action)
    case types.CHANGE_POSITION:
      return changePositionReducer(state, action)
    case types.SELECT_TEMPLATE:
      return state.set('templateId', fromJS(action.templateId.toString()))
    case types.ADD_TEMPLATE_FIELD:
      return state.set('templateFields', state.get('templateFields').set(action.id, fromJS({name: action.name, value: action.value})))
    case types.REMOVE_TEMPLATE_FIELD:
      return state.set('templateFields', state.get('templateFields').remove(action.id))
    case types.SELECT_TEMPLATE_FIELD:
      return state.setIn(['templateFields', action.id, 'value'], action.field)
                  .setIn(['optionFields', action.id, 'value'], fromJS([]))
                  .setIn(['optionFields', action.id, 'associations'], fromJS([]))
                  .setIn(['optionFields', action.id, 'optionsType'], SCOPE_ALL)
    case types.LOAD_ASSOCIATIONS:
      return state.setIn(['optionFields', action.id, 'associations'], fromJS(action.data))
    case types.LOAD_LOCATION_ELEMENT_ASSOCIATIONS:
      return state.setIn(['optionFields', action.id, 'associations'], fromJS(action.data))
    case types.SELECT_DEFAULT_ASSOCIATION:
      return state.setIn(['optionFields', action.id, 'defaultOption'], fromJS(action.value))
  }
  return state
}
