import { useEffect, useRef } from 'react'
import classNames from 'classnames'
import FormInputComponents from './form_inputs'
const { DropZone, FileList, HiddenInputs } = FormInputComponents
import { VCSpinner } from 'components/shared/spinner'

let submitBtn = null
let submitInitiallyDisabled = false

const FormUploaderInput = ({
  files, filesOptions, buttonId, modelData, dropElementId,
  inputDescription, attachmentCategoryUrl, fileActions,
  autosave, isReqStateInProgress
}) => {

  const { uploadButtonCaption } = filesOptions
  const ref = useRef()

  useEffect(() => {
    submitBtn = ref.current.closest('form')?.querySelector('input[type=submit]')
    if (submitBtn && submitBtn.disabled) {
      submitInitiallyDisabled = true
    }
    return () => submitBtn = null
  }, [])

  useEffect(() => {
    if (submitBtn)  {
      if (isReqStateInProgress) {
        submitBtn.setAttribute('disabled', 'disabled')
      } else {
        if (!submitInitiallyDisabled) {
          submitBtn.removeAttribute('disabled')
        }
      }
    }
  }, [isReqStateInProgress])

  return (
    <div className="files-uploader" ref={ref}>
      <FileList files={files} filesOptions={filesOptions} buttonId={buttonId} isReqStateInProgress={isReqStateInProgress} {...fileActions} />
      <HiddenInputs files={files} modelData={modelData} enabled={!autosave} />
      <div className="clear" />
      <div className="text-center">
        <VCSpinner visible={isReqStateInProgress} />
      </div>
      <div id={dropElementId} className={classNames("files-drop-zone", { hidden: isReqStateInProgress})}>
        <DropZone buttonId={buttonId} uploadButtonCaption={uploadButtonCaption} inputDescription={inputDescription} dropElementId={dropElementId} />
      </div>
    </div>
  )
}

export default FormUploaderInput
