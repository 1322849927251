import { types } from 'actions/project_actions'
import { fromJS } from 'immutable'
import transient from './transient'

const reduceLinks = (state = {}, action, { activeElement }) => {
  if (action.selected) {
    return state.get('links').push(fromJS({ element_id: activeElement, attachment_id: action.photoId }))
  } else {
    return state.get('links').filter(link => link.get('element_id') !== activeElement || link.get('attachment_id') !== action.photoId)
  }
}

function reducer (state = {}, action, extra) {
  switch (action.type) {
    case types.LOAD_PROJECT:
      return fromJS({ ...action.project, links: action.links, photos: action.photos })
    case types.TOGGLE_PHOTO:
      return state.set('links', reduceLinks(state, action, extra))
    case types.RESET_LINKS:
      return state.set('links', state.getIn(['transient', 'reservedLinks']))
  }
  return state
}

export default function (state, action, extra) {
  return reducer(state, action, extra).set('transient', transient(state.get('transient'), action, { links: state.get('links') }))
}
