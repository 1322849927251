import { fromJS, Map } from 'immutable'
import locationsStep from './locations_step'
import elementsStep from './elements_step'
import summaryStep from './summary_step'
import { types } from 'actions/campaigns/common_actions'

const locatioinsByTags = (locations, tagsById, tagIds) => {
  return locations.map(iItem => {
    const itemTags = tagIds.reduce((result, tagId) => {
      if (iItem.get('tagIDs').includes(tagId)) { result.push(tagsById[tagId]) }
      return result
    }, [])

    if (itemTags.length > 0) {
      return iItem.merge(fromJS({ matchedTags: itemTags, selected: true }))
    }
    else if (iItem.get('matchedTags') && iItem.get('matchedTags').size > 0) {
      return iItem.merge(fromJS({ matchedTags: [], selected: false }))
    }
    return iItem
  })
}

const elementsByTags = (elements, tagsById, tagIds) => {
  return elements.map(item => {
    const itemTags = tagIds.reduce((result, tagId) => {
      if (item.tagIDs.includes(tagId)) { result.push(tagsById[tagId]) }
      return result
    }, [])

    if (itemTags.length > 0) {
      item.update({ matchedTags: itemTags, selected: true })
    }
    else if (item.matchedTags && item.matchedTags.length > 0) {
      item.update({ matchedTags: [], selected: false })
    }
    return item
  })
}

const selectItemsByTags = (state, tags, itemType) => {
  const tagsById = tags.reduce((result, tag) => {
    result[tag.value] = tag
    return result
  }, {})
  const tagIds = tags.map(t => (t.value || t.id))
  const iTags = state.get('tags').map(iTag =>
    iTag.merge({ 'selected': !!tagIds.includes(iTag.get('value')) })
  )
  const itemTypeFunction = (itemType == 'elements' ? elementsByTags : locatioinsByTags)

  return state.merge({
    [itemType]: itemTypeFunction(state.get(itemType), tagsById, tagIds),
    tags: iTags
  })
}

function reducer (state, action) {
  const newState = summaryStep(elementsStep(locationsStep(state, action), action), action)

  switch (action.type) {
    case types.SELECT_BY_TAGS:
      return selectItemsByTags(newState, action.tags, action.itemType)
    default:
      return newState
  }
}

export default reducer
