import { fromJS } from 'immutable'
import { types } from '../../actions/reports/projects_report'

export default function reducer(state, action) {
  switch (action.type) {
    case types.SET_AVAILABLE_FILTERS:
      return state.set('availableFilters', fromJS(action.filters))
    case types.SET_SELECTED_FILTERS:
      return state.set('selectedFilters', fromJS(action.filters))
    case types.LOAD_AVAILABLE_FILTERS:
      return state.set('availableFilters', fromJS(action.data.filters))
    case types.SET_SAVED_FILTERS:
      return state.set('savedFilters', fromJS(action.filters))
    case types.UPDATE_USER_FILTERS:
      return state.set('savedFilters', fromJS(action.data.filters))
    default:
      return state
  }
}
