import React from 'react'

const PREVIEW_WIDTH = 600
const PREVIEW_HEIGHT = 400

export default class PreviewImageItem extends React.Component {

  static defaultProps = {
    style: {},
    previewPosition: {
      bottom: -50,
      right: 10,
    }
  }

  // TODO (atanych): should be replaced with react 16 Portals later
  onMouseEnter = (e) => {
    if (!this.props.preview) { return null }
    this.$preview = $('<div />').appendTo('body').addClass("preview-image-item").css(this.getPreviewStyles())
  }

  // TODO (atanych): should be replaced with react 16 Portals later
  onMouseLeave = (e) => {
    if (!this.props.preview) { return null }
    this.$preview.remove()
  }

  // TODO (atanych): should be replaced with react 16 Portals later
  getPreviewStyles () {
    let previewStyles = {}
    previewStyles.backgroundImage = `url(${this.props.src})`
    const offset = $(this.container).offset()
    previewStyles.top = offset.top
    previewStyles.left = offset.left
    if (this.props.previewPosition.top !== undefined) { previewStyles.top = previewStyles.top + this.props.previewPosition.top }
    if (this.props.previewPosition.left !== undefined) { previewStyles.left = previewStyles.left + this.props.previewPosition.left }
    if (this.props.previewPosition.bottom !== undefined) { previewStyles.top = previewStyles.top - this.props.previewPosition.bottom - PREVIEW_HEIGHT }
    if (this.props.previewPosition.right !== undefined) { previewStyles.left = previewStyles.left - this.props.previewPosition.right - PREVIEW_WIDTH }

    return previewStyles
  }

  onClick = (e) => {
    if (this.props.onClick) { this.props.onClick(e) }
  }

  render () {
    return (
      <a
        style={this.props.style}
        ref={(ref) => { this.container = ref }}
        className={this.props.className}
        href={this.props.src}
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {this.props.children}
      </a>
    )
  }
}
