import { combineReducers } from 'redux-immutable'

import conditions from './conditions'

const reducer = combineReducers({
  conditions,
})


export default reducer
