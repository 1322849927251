import { types } from 'actions/rent_list_actions'
import { fromJS, List } from 'immutable'

function rentElement (state, action) {
  const itemsPath = ['rentedItems', action.data.element.id.toString()]
  let items = state.getIn(itemsPath)
  if (!items) { items = List() }

  return state.setIn(itemsPath, items.push(fromJS(action.data)))
    .set('rentElementRequestInProgress', false)
}

function unrentItem (state, action) {
  const itemsPath = ['rentedItems', action.data.element.id.toString()]
  const rentListId = state.get('rentListId')
  const rentedItems = state.getIn(itemsPath)
    .filter(i => i.getIn(['rentList', 'id']) !== rentListId)

  return state.setIn(itemsPath, rentedItems)
    .set('rentElementRequestInProgress', false)
}

export default function reducer (state, action) {
  switch (action.type) {
    case types.LOAD_DATA:
      return state.merge(fromJS({
        elementGroups: action.data.elementGroups,
        groupedAvailableElements: action.data.groupedAvailableElements,
        rentedItems: action.data.rentedItems,
        rentListId: action.data.rentListId,
      }))
    case types.RENT_ELEMENT_REQUEST:
      return state.set('rentElementRequestInProgress', true)
    case types.RENT_ELEMENT:
      return rentElement(state, action)
    case types.RENT_ELEMENT_FAILURE:
      return state.set('rentElementRequestInProgress', false)
        .set('errors', state.get('errors').push(action.error.response.data.error))
    case types.UNRENT_ITEM_REQUEST:
      return state.set('rentElementRequestInProgress', true)
    case types.UNRENT_ITEM:
      return unrentItem(state, action)
    case types.UNRENT_ITEM_FAILURE:
      return state.set('rentElementRequestInProgress', false)
        .set('errors', state.get('errors').push(action.error.response.data.error))
    case types.CLEAR_ERRORS:
      return state.set('errors', List())
    default:
      return state
  }
}
