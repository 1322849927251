import { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap';

const I18n = window.I18n

export default class ControlMenu extends Component {

  state = { modal: false, tooltipOpen: false }

  constructor (props) {
    super(props)
    this.removeImage = this.removeImage.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.toggleTooltip = this.toggleTooltip.bind(this)
  }

  toggleTooltip = () => {
    this.setState(prevState => ({
      modal: prevState.modal,
      tooltipOpen: !prevState.tooltipOpen
    }))
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      tooltipOpen: prevState.tooltipOpen
    }))
  }

  removeImage = (e) => {
    this.toggleModal()
    this.props.removeImage(this.props.image.id)
  }

  render () {
    const { image, removeImage } = this.props
    return (
      <div className="control-menu">
        <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={`image-${image.id}`} toggle={this.toggleTooltip} autohide={false}>
          <i className="fa fa-trash" onClick={this.toggleModal}></i>
        </Tooltip>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggle}>{I18n.t('application.confirmation_window.title')}</ModalHeader>
          <ModalBody>{I18n.t('application.confirmation_window.body')}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>{I18n.t('application.actions.cancel')}</Button>
            <Button color="primary" onClick={this.removeImage}>{I18n.t('application.actions.ok')}</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    )
  }

}
