import { types, getNodeKey } from 'actions/manual_pages/admin_actions'
import { fromJS, List, Map } from 'immutable'
import {
  addNodeToTree, updateNodeInTree, deleteNodeFromTree, selectPageForEdit,
  resetFormData , callWithCompletedPagePath
} from './helpers'

function reducer(state = {}, action) {

  switch (action.type) {
    case types.INIT:
      return state.set('treeData', fromJS(action.treeData))
    case types.UPDATE_TREE_ITEMS:
      return resetFormData(state.set('treeData', fromJS(action.treeData)))
    case types.NEW:
      return state.set('page', fromJS(action.page))
    case types.CREATE:
      return resetFormData(addNodeToTree(state, action.data.page))
    case types.EDIT:
      return callWithCompletedPagePath(action.data.page,
        (page) => selectPageForEdit(updateNodeInTree(state, page), page)
      )
    case types.SOFT_UPDATE:
      return state.set('page', fromJS(action.data))
    case types.UPDATE:
      return callWithCompletedPagePath(action.data.page,
        (page) => resetFormData(updateNodeInTree(state, page))
      )
    case types.CHANGE_POSITION:
      return callWithCompletedPagePath(action.data.page,
        (page) => resetFormData(updateNodeInTree(state, page))
      )
    case types.DELETE:
      return deleteNodeFromTree(state, action)
  }

  return state
}

export default reducer
