import { fromJS, List, Map } from 'immutable'
import { types } from 'actions/campaign_postorders/create_postorder_form'

const updateStateBasedResponse = (state, responseData) => {
  const { location_id, items } = responseData
  return state.merge({
    locationId: location_id,
    items: fromJS(items)
  })
}

const updateItemData = (state, campaignElementId, data) => {
  const items = state.get('items')
  const itemIndex = items.findIndex(item => item.get('campaignElementId') == campaignElementId)
  return state.updateIn(['items', itemIndex], (item) => item.merge(fromJS(data)))
}

function reducer (state, action) {
  switch (action.type) {
    case types.INIT:
      const { campaignId, locationId, locations, items } = action
      return fromJS({ campaignId, locationId, locations, items })
    case types.GET_ITEMS:
      return updateStateBasedResponse(state, action.data)
    case types.UPDATE_ITEM:
      return updateItemData(state, action.campaignElementId, action.data)
    case types.CREATE_POSTORDER_WITH_ITEMS:
      return state
  }
  return state
}

export default reducer
