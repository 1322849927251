import { fromJS } from 'immutable'

export const deleteItem = (state, statePath) => {
  if (parseInt(state.getIn(statePath.concat('id')))) {
    return state.mergeIn(statePath, fromJS({ _destroy: '1'}))
  }
  return state.deleteIn(statePath)
}

export const getFullPath = (path) => {
  const [rowIndex, cellIndex, elementPosition] = path
  let resultPath = ['data']

  if (rowIndex || rowIndex == 0) { resultPath = resultPath.concat(['rows', rowIndex]) }
  if (cellIndex || cellIndex == 0) { resultPath = resultPath.concat(['cells', cellIndex]) }
  if (elementPosition || elementPosition == 0) {
    resultPath = resultPath.concat(['elements', elementPosition])
  }

  return resultPath
}

const getTargetElementPath = (toFullPath, isCellPath) => {
  if (isCellPath) { return toFullPath.concat('elements') }

  return toFullPath.slice(0, -1)
}

export class ElementTransporter {
  constructor (state) {
    this._state = state
  }

  move = (action) => {
    if (action.fromPath.length < 3 || action.toPath < 2) { return this._state }

    const options = {
      fromPath: action.fromPath, toPath: action.toPath,
      fromFullPath: getFullPath(action.fromPath), toFullPath: getFullPath(action.toPath),
    }
    options.targetElementsPath = getTargetElementPath(options.toFullPath, options.toPath.length == 2)

    if (action.fromPath[0] == action.toPath[0] && action.fromPath[1] == action.toPath[1]) {
      return this.changePosition(options)
    }
    else if (action.toPath.length == 2) { return this.moveToAnotherCell(options) }
    else if (action.toPath.length == 3) { return this.moveToAnotherCellToSpecificPosition(options) }

    return this._state
  }

  moveToAnotherCell = ({ fromFullPath, toFullPath, targetElementsPath }) => {
    const [stateAfterDelete, element] = this.deleteElement(fromFullPath)
    const { elements, deletedElements } = this.splitElements(stateAfterDelete.getIn(targetElementsPath))

    return stateAfterDelete.setIn(targetElementsPath, elements.push(element).concat(deletedElements))
  }

  moveToAnotherCellToSpecificPosition = ({ fromFullPath, toFullPath, targetElementsPath }) => {
    const [stateAfterDelete, element] = this.deleteElement(fromFullPath)
    const { elements, deletedElements } = this.splitElements(stateAfterDelete.getIn(targetElementsPath))
    const elementPosition = toFullPath[toFullPath.length - 1]

    return stateAfterDelete.setIn(targetElementsPath,
      elements.splice(elementPosition, 0, element).concat(deletedElements)
    )
  }

  changePosition = ({ fromPath, toPath, fromFullPath, toFullPath, targetElementsPath }) => {
    const [stateAfterDelete, element] = this.deleteElement(fromFullPath, true)
    const { elements, deletedElements } = this.splitElements(stateAfterDelete.getIn(targetElementsPath))
    const oldPos = fromPath[fromPath.length - 1]
    const newPos = toPath[toPath.length - 1]

    const finalElementList = elements.insert(newPos, element).concat(deletedElements)
    return stateAfterDelete.setIn(targetElementsPath, finalElementList)
  }

  splitElements = (allElements) => {
    return {
      elements: allElements.filterNot(el => el.get('_destroy')),
      deletedElements: allElements.filter(el => el.get('_destroy'))
    }
  }

  deleteElement = (deletePath, force = false) => {
    let element = this._state.getIn(deletePath)

    if (element.get('id') && !force) {
      element = element.set('_destroy', true)
      return [this._state.setIn(deletePath, element), element.delete('id').delete('_destroy')]
    }

    return [this._state.deleteIn(deletePath), element]
  }

}

export const moveRowItem = (state, action) => {
  if (action.newPos < 0) { return state }
  else {
    const row = state.getIn(['data', 'rows', action.oldPos])
    const rows = state.getIn(['data', 'rows']).splice(action.oldPos, 1).insert(
      action.newPos, row
    )
    return state.setIn(['data', 'rows'], rows)
  }
}
