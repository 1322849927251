import { fromJS, List, Map } from 'immutable'
import { cloneDeep } from 'lodash'
import CampaignElementItem from 'models/campaigns/element'
import { types } from 'actions/campaigns/elements_selector_actions'

const updateItemsAttributes = (state, cIDs, attrs) => {
  let elements = state.get('elements')
  elements = elements.reduce((result, el) => {
    if (cIDs.some(cID => el.cID.isEqual(cID))) {
      el.update(attrs)
      result = result.push(el)
    }
    else { result = result.push(el) }

    return result
  }, new List([]))

  return state.set('elements', elements)
}

const makeItemsSelected = (state, ids, selected) => {
  return updateItemsAttributes(state, ids, { selected })
}

const updateItemImageList = (state, item, images) => {
  item = CampaignElementItem.reInitCopy(item)
  const elIndex = state.get('elements').findIndex(el => el.cID.isEqual(item.cID))
  item.update({ images: images })
  return state.setIn(['elements', elIndex], item)
}

const mergeItemImages = (state, item, images) => {
  let itemImages = cloneDeep(item.images)
  const newImages = images.reduce((result, img) => {
    const itemImgIndex = itemImages.findIndex(i => i.id == img.id)
    const itemImg = (itemImgIndex != -1) ? itemImages[itemImgIndex] : null
    if (itemImg) { itemImages[itemImgIndex] = Object.assign({}, cloneDeep(itemImg), img) }
    else { result.push(img) }
    return result
  }, [])

  itemImages = itemImages.concat(newImages)
  return updateItemImageList(state, item, itemImages)
}

const addNewImageToItem = (state, item, data) => {
  const images = cloneDeep(item.images).concat(data)
  return updateItemImageList(state, item, images)
}

const updateItemImage  = (state, item, data)  => {
  const images = cloneDeep(item.images)
  const imgIndex = item.images.findIndex(img => img.id == data.id)
  if (imgIndex != -1) { 
    images[imgIndex] = Object.assign({}, images[imgIndex], data)
  }
  return updateItemImageList(state, item, images)
}

const removeItem = (state, item) => {
  let newState = state
  const elements = newState.get('elements')

  if (item.isCopy) {
    return newState.set('elements', item.deleteChild(elements))
  }
  else {
    if (item.descendantsNum) {
      newState = newState.set('elements', item.deleteChildren(elements))
    }
    return makeItemsSelected(newState, [item.cID], false)
  }
}

const makeItemCopy = (state, item) => {
  const copyItem = item.clone()
  const elIndex = state.get('elements').findIndex(el => el.cID.isEqual(item.cID))
  return state.set(
    'elements',
    state.get('elements').insert(item.calcPositionForCopy(elIndex), copyItem)
  )
}

const removeItemImage = (state, item, imgId) => {
  let images = cloneDeep(item.images)
  const index = images.findIndex(img => img.id == imgId)
  if (isNaN(imgId)) { images.splice(index, 1) }
  else { images[index]._destroy = true }
  return updateItemImageList(state, item, images)
}

function reducer (state = new Map({}), action) {

  const { item, data } = action

  switch (action.type) {
    case types.INIT_ELEMENTS:
      const { id, step, tags, elementFormData, isReady } = action
      const elements = CampaignElementItem.createList(action.elements)
      return fromJS({ elements, id, step, tags, elementFormData, isReady })
    case types.SELECT_ITEMS:
      return makeItemsSelected(state, action.ids, true)
    case types.SELECT_TAGS:
      return updateItemsAttributes(
        state.set('activeItem', null),
        [item.cID],
        { tags: action.tags }
      )
    case types.SET_TAG_AMOUNT:
      const newTagsAmounts = Object.assign({}, item.tagsAmounts, { [action.tagId]: action.amount })
      return updateItemsAttributes(state, [item.cID], { tagsAmounts: newTagsAmounts })
    case types.REMOVE_ITEM:
      return removeItem(state, item)
    case types.UPDATE_ITEM:
      const attrs = { [action.attr]: action.value }
      return updateItemsAttributes(state, [item.cID], attrs)
    case types.ASSIGN_ACTIVE_ITEM:
      return state.set('activeItem', item)
    case types.ADD_NEW_IMAGE_TO_ITEM:
      return addNewImageToItem(state, item, data)
    case types.UPDATE_ITEM_IMAGE:
      return updateItemImage(state, item, Object.assign({}, data, { src: data.url }))
    case types.UPDATE_ITEM_IMAGE_LIST:
      return updateItemImageList(state, item, action.images)
    case types.MERGE_ITEM_IMAGES:
      return mergeItemImages(state, item, action.images)
    case types.REMOVE_ITEM_IMAGE:
      return removeItemImage(state, item, action.imageId)
    case types.MAKE_COPY:
      return makeItemCopy(state, item)
    case types.SAVE_SELECTED:
      return state
    case types.CREATE_ELEMENT:
      const newItem = new CampaignElementItem(action.data)
      return state.update('elements', elements => elements.push(newItem))
    case types.ADD_ALL_ELEMENTS:
      return state.update('elements', elements => elements.map(el => {
        if (action.isLocation == el.isLocation) { el.update({selected: true}) }
        return el
      }))
  }
  return state
}

export default reducer
