const OPEN_IMAGES_MODAL = 'project/OPEN_IMAGES_MODAL'
const TOGGLE_PHOTO = 'project/TOGGLE_PHOTO'
const RESET_LINKS = 'project/RESET_LINKS'
const SAVE_LINKS = 'project/SAVE_LINKS'
const LOAD_PROJECT = 'project/LOAD_PROJECT'

export const types = { OPEN_IMAGES_MODAL, TOGGLE_PHOTO, RESET_LINKS, SAVE_LINKS, LOAD_PROJECT }

export const openImagesModal = elementId => ({
  type: OPEN_IMAGES_MODAL,
  elementId,
  modal: {
    open: true,
    id: 'attach-photo-to-element',
  },
})

export const togglePhoto = (photoId, selected) => ({ type: TOGGLE_PHOTO, selected, photoId })

export const resetLinks = () => ({ type: RESET_LINKS })

export const loadProject = data => ({ type: LOAD_PROJECT, ...data })

export const saveLinks = (projectId, elementId, photoIds) => ({
  type: SAVE_LINKS,
  request: {
    url: `/project_tickets/${projectId}/save_attachment_links`,
    method: 'post',
    body: {
      photo_ids: photoIds,
      element_id: elementId,
    },
    modal: {
      close: true,
      id: 'attach-photo-to-element',
    },
  },
})
