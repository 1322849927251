import React  from 'react'
import Select from 'components/shared/base_react_select'
import storeFactory from 'stores/value_store'
import { isEmpty } from 'lodash'

class SmReactSelect extends React.Component {

  constructor (props) {
    super(props)
    const StoreClass =  storeFactory(this.props.store_type)
    this.store = new StoreClass(this.props.value)
  }

  onChange (data) {
    this.store.setValue(data)
    this.forceUpdate()
  }

  renderHiddenInputs() {
    const data = this.store.valueForInput()
    const { name } = this.props
    if (!Array.isArray(data)) {
      const value = data && data.hasOwnProperty('value') ? data.value : data
      if (typeof(value) === 'object' && isEmpty(value)) {
        return <input type="hidden" value="" name={name} />
      }
      return <input type="hidden" defaultValue={value} name={name} />
    }
    if (!data.length) {
      return <input type="hidden" value="" name={`${name}[]`} />
    }

    return (
      <div className='hidden-inputs-container'>
        { data.map((value, index) =>
            <input
              type="hidden"
              name={name + '[]'}
              value={ value.value }
              key= { name + index }
            />
          )
        }
      </div>
    )
  }

  currentValue = () => {
    if (this.store.isMulti()) { return this.store.value }
    return this.props.options.find(o => o.value == this.store.value)
  }

  render () {

    return (
      <div className="sm-react-select">
        <Select
          isMulti={this.store.isMulti()}
          value={this.currentValue()}
          options={this.props.options}
          onChange={ (opt) => this.onChange(opt) }
        />
        { this.renderHiddenInputs() }
      </div>
    )
  }
}

export default SmReactSelect
