import { types } from 'actions/project_actions'
import { Map } from 'immutable'
import conditions from './conditions'

const handleId = (state = null, action) => {
  if (action.type === action.LOAD_TAG) { return action.tag.id }
  return state
}

const DEFAULT_ELEMENT_FIELDS = {
  group: { type: 'group' },
  condition: { type: 'condition', condition: { table: 'elements' } },
}

const DEFAULT_LOCATION_FIELDS = {
  group: { type: 'group' },
  condition: { type: 'condition', condition: { table: 'locations' } },
}

export default function (state = Map({}), action) {
  return Map({
    id: handleId(state.get('id'), action),
    conditions_for_locations: conditions(state.get('conditions_for_locations'), action, 'conditions_for_locations', DEFAULT_LOCATION_FIELDS),
    conditions_for_elements: conditions(state.get('conditions_for_elements'), action, 'conditions_for_elements', DEFAULT_ELEMENT_FIELDS),
  })
}
