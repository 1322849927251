const INIT_ELEMENTS           = 'campaigns/elements_selector/INIT_ELEMENTS'
const SELECT_ITEMS            = 'campaigns/elements_selector/SELECT_ITEMS'
const UPDATE_ITEM             = 'campaigns/elements_selector/UPDATE_ITEM'
const REMOVE_ITEM             = 'campaigns/elements_selector/REMOVE_ITEM'
const ASSIGN_ACTIVE_ITEM      = 'campaigns/elements_selector/ASSIGN_ACTIVE_ITEM'
const SAVE_SELECTED           = 'campaigns/elements_selector/SAVE_ELEMENTS'

const UPDATE_ITEM_IMAGE_LIST  = 'campaigns/elements_selector/UPDATE_ITEM_IMAGE_LIST'
const UPDATE_ITEM_IMAGE       = 'campaigns/elements_selector/UPDATE_ITEM_IMAGE'
const MERGE_ITEM_IMAGES       = 'campaigns/elements_selector/MERGE_ITEM_IMAGES'
const ADD_NEW_IMAGE_TO_ITEM   = 'campaigns/elements_selector/ADD_NEW_IMAGE_TO_ITEM'
const REMOVE_ITEM_IMAGE       = 'campaigns/elements_selector/REMOVE_ITEM_IMAGE'

const MAKE_COPY               = 'campaigns/elements_selector/MAKE_COPY'
const CREATE_ELEMENT          = 'campaigns/elements_selector/CREATE_ELEMENT'
const ADD_ALL_ELEMENTS        = 'campaigns/elements_selector/ADD_ALL_ELEMENTS'
// Select tags for selected element
const SELECT_TAGS             = 'campaigns/elements_selector/SELECT_TAGS'
const SET_TAG_AMOUNT      = 'campaigns/elements_selector/SET_TAG_AMOUNT'

const NEXT_STEP               = 'campaigns/elements_selector/NEXT_STEP'
const STEP_BACK               = 'campaigns/elements_selector/STEP_BACK'

export const types = { INIT_ELEMENTS, SELECT_ITEMS,
  UPDATE_ITEM, REMOVE_ITEM, ASSIGN_ACTIVE_ITEM, SAVE_SELECTED,
  UPDATE_ITEM_IMAGE_LIST, MERGE_ITEM_IMAGES, ADD_NEW_IMAGE_TO_ITEM, UPDATE_ITEM_IMAGE, REMOVE_ITEM_IMAGE, 
  MAKE_COPY, CREATE_ELEMENT,
  ADD_ALL_ELEMENTS, SELECT_TAGS, SET_TAG_AMOUNT, NEXT_STEP, STEP_BACK
}

export const initElements = (data) => ({ type: INIT_ELEMENTS, ...data})

export const selectItems = (ids) => ({ type: SELECT_ITEMS, ids })

export const removeItem = (item) => ({ type: REMOVE_ITEM, item })

export const updateItem = (item, attr, value) => ({ type: UPDATE_ITEM, item, attr, value })

export const updateItemImageList = (item, images) => ({ type: UPDATE_ITEM_IMAGE_LIST, item, images })

export const mergeItemImages = (item, images) => ({ type: MERGE_ITEM_IMAGES, item, images })

export const addNewImageToItem = (item, data) => ({ type: ADD_NEW_IMAGE_TO_ITEM, item, data })

export const updateItemImage = (item, data) => ({ type: UPDATE_ITEM_IMAGE, item, data })

export const removeItemImage = (item, imageId) => ({type: REMOVE_ITEM_IMAGE, item, imageId})

export const selectTags  = (item, tags) => ({ type: SELECT_TAGS, item, tags })

export const setTagAmount = (item, tagId, amount) => ({ type: SET_TAG_AMOUNT, item, tagId, amount })

export const makeCopy   = (item) => ({ type: MAKE_COPY, item })

export const assignActiveItem = (item) => ({ type: ASSIGN_ACTIVE_ITEM, item })

export const addAllElements = (data) => ({ type: ADD_ALL_ELEMENTS, ...data })

export const saveSelected = (campaignId, campaignData, campaignElementsData, stepAction = null, next = null) => {
  const data = Object.assign({}, {
    step: 'elements',
    campaign_elements_attributes: campaignElementsData
  }, campaignData || {})

  if (stepAction) { data['step_action'] = stepAction }
  if (next) { data['next'] = next }

  return ({
    type: SAVE_SELECTED,
    request: {
      url: `/campaigns/${campaignId}/background_update.json`,
      method: 'patch',
      body: data,
      redirectTo: true
    }
  })
}

export const createElement = (campaignId, data) => ({
  type: CREATE_ELEMENT,
  request: {
    url: '/elements/campaign_elements',
    method: 'post',
    body: {
      format: 'json',
      campaign_id: campaignId,
      ...data
    }
  }
})

export const nextStep = (campaignId) => ({
  type: NEXT_STEP,
  request: {
    url: `/campaigns/${campaignId}`,
    method: 'put',
    body: {
      step: 'elements',
      next: true,
      format: 'json'
    },
    redirectTo: true
  }
})

export const stepBack = (campaignId) => ({
  type: STEP_BACK,
  request: {
    url: `/campaigns/${campaignId}`,
    method: 'put',
    body: {
      step: 'locations',
      back: true,
      format: 'json'
    },
    redirectTo: true
  }
})
