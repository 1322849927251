import { types } from 'actions/multi_step/workflow_overview_board_actions'
import { fromJS } from 'immutable'

export default function reducer (state = {}, action) {
  const { data } = action

  switch (action.type) {
    case types.INIT:
      return state.merge(fromJS(data))
    case types.SELECT_FILTER_OPTION:
      const { opts, filterType } = action
      const selectedIds = opts.map(opt => opt.value)
      const updatedFilterItems = state.getIn(['filtersData', filterType]).map(item => (
        item.merge({'selected': selectedIds.includes(item.get('value'))})
      ))
      return state.setIn(['filtersData', filterType], updatedFilterItems)
  }
  return state
}