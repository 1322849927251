import { Map, List, fromJS } from 'immutable'
import { isUndefined, uniq } from 'underscore'

import { types } from '../../actions/elements_order'

function findElementIndex (state, finderKey, finderValue) {
  return state.get('elements').findIndex(element => element.get(finderKey) == finderValue)
}

function loadTemplatesReducer (state, action, extra) {
  const templates = action.data
  const allSubGroups = []
  const allDepartments = []

  templates.forEach((t) => {
    if (!isUndefined(t.fields.sub_group)) {
      const subGroupId = parseInt(t.fields.sub_group.default_value, 10)
      allSubGroups.push(subGroupId)
    }
    if (!isUndefined(t.fields.department)) {
      const departmentId = parseInt(t.fields.department.default_value, 10)
      allDepartments.push(departmentId)
    }
  })

  let filteredSubGroups = state.getIn(['filtered', 'elementSubGroups'])
  if (!state.get('currentElementSubGroup')) {
    filteredSubGroups = uniq(allSubGroups)
  }
  let filteredDepartments = state.getIn(['filtered', 'departments'])
  if (!state.get('currentDepartment')) {
    filteredDepartments = uniq(allDepartments)
  }

  const filtered = {
    elementSubGroups: filteredSubGroups,
    departments: filteredDepartments,
  }

  return state.set('templates', fromJS(templates))
    .set('templatesLoading', false)
    .set('filtered', fromJS(filtered))
}

function loadInstalledElementsReducer (state, action) {
  const installedElements = action.data
  const allSubGroups = []
  const allDepartments = []

  installedElements.forEach((t) => {
    if (!isUndefined(t.sub_group_id)) {
      const subGroupId = parseInt(t.sub_group_id, 10)
      allSubGroups.push(subGroupId)
    }
    if (!isUndefined(t.department_id)) {
      const departmentId = parseInt(t.department_id, 10)
      allDepartments.push(departmentId)
    }
  })

  let filteredSubGroups = state.getIn(['filtered', 'elementSubGroups'])
  if (!state.get('currentElementSubGroup')) {
    filteredSubGroups = uniq(allSubGroups)
  }
  let filteredDepartments = state.getIn(['filtered', 'departments'])
  if (!state.get('currentDepartment')) {
    filteredDepartments = uniq(allDepartments)
  }

  const filtered = {
    elementSubGroups: filteredSubGroups,
    departments: filteredDepartments,
  }

  return state.set('installedElements', fromJS(installedElements))
    .set('installedElementsLoading', false)
    .set('filtered', fromJS(filtered))
}

function removeElementReducer (state, action) {
  const elements = state.get('elements').delete(action.key.toString())

  let newState = state.set('elements', elements)
  if (action.id) {
    newState = newState.set('removed', state.get('removed').unshift(action.id))
  }
  return newState
}

function clearFormReducer (state, action, extra) {
  const elements = state.get('elements')
  let removed = state.get('removed')
  elements.forEach((e) => {
    const id = e.get('id')
    if (id) {
      removed = removed.unshift(id)
    }
  })
  return state.set('elements', new List())
    .set('removed', removed)
}

export default function reducer (state = new Map(), action, extra) {
  switch (action.type) {
    case types.LOAD_ELEMENTS:
      return state.set('elements', fromJS(action.elements))
    case types.LOAD_DICTIONARIES:
      return state.set('dictionaries', fromJS(action.dictionaries))
    case types.LOAD_TEMPLATES_REQUEST:
      return state.set('templatesLoading', true)
        .set('currentReplaceElementId', null)
        .set('currentTemplateData', null)
        .set('currentTemplate', null)
        .set('currentElement', null)
    case types.LOAD_TEMPLATES_FAILURE:
      return state.set('templatesLoading', false)
    case types.LOAD_TEMPLATES:
      return loadTemplatesReducer(state, action, extra)
    case types.LOAD_TEMPLATE_REQUEST:
      return state.set('currentTemplate', Number(action.templateId))
        .set('currentReplaceElementId', null)
        .set('currentTemplateData', null)
    case types.LOAD_TEMPLATE:
      return state.set('currentTemplateData', fromJS(action.data))
        .set('currentElement', null)
    case types.LOAD_TEMPLATE_FAILURE:
      return state.set('currentTemplateData', null)
        .set('currentElement', null)
    case types.LOAD_INSTALLED_ELEMENTS_REQUEST:
      return state.set('installedElementsLoading', true)
    case types.LOAD_INSTALLED_ELEMENTS_FAILURE:
      return state.set('installedElementsLoading', false)
    case types.LOAD_INSTALLED_ELEMENTS:
      return loadInstalledElementsReducer(state, action)
    case types.LOAD_INSTALLED_ELEMENT:
      return state.set('currentTemplateData', fromJS(action.data))
        .set('currentElement', null)
        .set('currentTemplate', Number(action.data.id))
    case types.LOAD_INSTALLED_ELEMENT_REQUEST:
      return state.set('currentReplaceElementId', Number(action.elementId))
        .set('currentTemplateData', null)
    case types.LOAD_INSTALLED_ELEMENT_FAILURE:
      return state.set('currentTemplateData', null)
        .set('currentElement', null)
    case types.SELECT_ELEMENT_GROUP:
      return state.set('currentElementGroup', Number(action.elementGroupId))
        .set('currentElementSubGroup', null)
        .set('currentDepartment', null)
    case types.SELECT_ELEMENT_SUB_GROUP:
      return state.set('currentElementSubGroup', Number(action.elementSubGroupId))
        .set('currentDepartment', null)
    case types.SELECT_DEPARTMENT:
      return state.set('currentDepartment', Number(action.departmentId))
    case types.SEARCH_TEMPLATE:
      return state.set('searchTerm', action.searchTerm)
    case types.ADD_ELEMENT:
      return state.setIn(['elements', action.key.toString()], action.element)
        .set('currentTemplateData', null)
        .set('currentTemplate', null)
        .set('currentElement', null)
    case types.UPDATE_ELEMENT:
      const updateIdx = findElementIndex(state, action.finderKey, action.finderValue)
      return state.setIn(['elements', updateIdx, action.field], action.value)
    case types.EDIT_ELEMENT:
      return state.set('currentTemplateData', null)
        .set('currentTemplate', null)
        .set('currentElement', action.element)
        .set('currentReplaceElementId', Number(action.element.get('replace_element_id') || null))
    case types.REMOVE_ELEMENT:
      return removeElementReducer(state, action)
    case types.CLEAR_FORM:
      return clearFormReducer(state, action, extra)
    default:
      return state
  }
}
