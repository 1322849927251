import { fromJS } from 'immutable'
import { ASSIGN_INSTALLERS_SUCCESS, ASSIGN_INSTALLERS_FAILURE } from '../../actions/project_ticket_actions'

export default function reducer(state, action) {
  switch (action.type) {
    case ASSIGN_INSTALLERS_SUCCESS:
      return state
        .set('errors', fromJS([]))
        .set('ready_to_reload_page', fromJS(true))
    case ASSIGN_INSTALLERS_FAILURE:
      return state
        .set('errors', fromJS(action.error.response.data['errors'] || action.error.message))
        .set('ready_to_reload_page', fromJS(true))
  }
  return state
}
