import _ from 'underscore'

const apiMiddleware = store => next => action => {
  if (!action.redirectTo) { return next(action) }

  const response = next(action)

  if (response.data && response.data.redirect_to) {
    window.location.href = response.data.redirect_to
  }
  return next(_.omit(response, 'redirectTo'))
}

export default apiMiddleware
