// component must define handleSorting: (newIds) function
// component must have ref named "me" pointing to root node for sorting
// children must have id properties
// you can pass additional options via this.props.sortableOptions in getDefaultProps
export default class SortableBehavior {
  constructor (context) {
    this.context = context
  }

  initSortable = () => {
    this.$ = $(this.context.refs.me.current)
    const defaultOptions = this.context.props.sortableOptions || {
      axis: 'y',
      items: '.file-info',
      handle: '.sort-handler',
    }
    const options = { ...defaultOptions, stop: this.handleSortingStop }
    this.$.sortable(options)
  }

  destroySortable = () => {
    if (this.$ === null) {
      return
    }
    this.$.sortable('destroy')
  }

  handleSortingStop = () => {
    const newIds = this.$.sortable('toArray').map(id => id.replace(/^uploader_/, ''))
    this.$.sortable('cancel')
    this.context.handleSorting(newIds)
  }
}
