import { removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree'
import { getNodeKey } from 'actions/manual_pages/admin_actions'
import { findNodeByPath } from './search'
import { fromJS } from 'immutable'

export const completePathForAction = (path, id) => [null].concat(path, id)

export const callWithCompletedPagePath = (page, stateUpdateFunc) => {
  page.path = completePathForAction(page.path, page.id)
  return stateUpdateFunc(page)
}

export const deleteNodeFromTree = (state, action) => {
  const path = completePathForAction(action.data.path, action.id)
  const treeData = state.get('treeData').toJS()
  const changedTreeData = removeNodeAtPath({ treeData, path, getNodeKey })
  return state.set('treeData', fromJS(changedTreeData))
}

export const updateNodeInTree = (state, page) => {
  const treeData = state.get('treeData').toJS()
  const changedTreeData = changeNodeAtPath({ treeData, path: page.path, newNode: page, getNodeKey })
  return state.set('treeData', fromJS(changedTreeData))
}

export const addNodeToTree = (state, page) => {
  const treeData = state.get('treeData').toJS()
  const parentNode = findNodeByPath(treeData, [null].concat(page.path))
  parentNode.children.push(page)
  return state.set('treeData', fromJS(treeData))
}

export const selectPageForEdit = (state, page) => state.set('page', fromJS(page))
export const resetFormData = (state) => selectPageForEdit(state, fromJS({}))
