const LOAD_ELEMENTS = 'elements_order/LOAD_ELEMENTS'
const LOAD_DICTIONARIES = 'elements_order/LOAD_DICTIONARIES'
const LOAD_TEMPLATES = 'elements_order/LOAD_TEMPLATES'
const LOAD_TEMPLATES_REQUEST = `${LOAD_TEMPLATES}_REQUEST`
const LOAD_TEMPLATES_FAILURE = `${LOAD_TEMPLATES}_FAILURE`
const LOAD_TEMPLATE = 'elements_order/LOAD_TEMPLATE'
const LOAD_TEMPLATE_REQUEST = `${LOAD_TEMPLATE}_REQUEST`
const LOAD_TEMPLATE_FAILURE = `${LOAD_TEMPLATE}_FAILURE`
const LOAD_INSTALLED_ELEMENTS = 'elements_order/LOAD_INSTALLED_ELEMENTS'
const LOAD_INSTALLED_ELEMENTS_REQUEST = `${LOAD_INSTALLED_ELEMENTS}_REQUEST`
const LOAD_INSTALLED_ELEMENTS_FAILURE = `${LOAD_INSTALLED_ELEMENTS}_FAILURE`
const LOAD_INSTALLED_ELEMENT = 'elements_order/LOAD_INSTALLED_ELEMENT'
const LOAD_INSTALLED_ELEMENT_REQUEST = `${LOAD_INSTALLED_ELEMENT}_REQUEST`
const LOAD_INSTALLED_ELEMENT_FAILURE = `${LOAD_INSTALLED_ELEMENT}_FAILURE`
const SELECT_DEPARTMENT = 'elements_order/SELECT_DEPARTMENT'
const SELECT_ELEMENT_GROUP = 'elements_order/SELECT_ELEMENT_GROUP'
const SELECT_ELEMENT_SUB_GROUP = 'elements_order/SELECT_ELEMENT_SUB_GROUP'
const SEARCH_TEMPLATE = 'elements_order/SEARCH_TEMPLATE'
const ADD_ELEMENT = 'elements_order/ADD_ELEMENT'
const UPDATE_ELEMENT = 'elements_order/UPDATE_ELEMENT'
const EDIT_ELEMENT = 'elements_order/EDIT_ELEMENT'
const REMOVE_ELEMENT = 'elements_order/REMOVE_ELEMENT'
const CLEAR_FORM = 'elements_order/CLEAR_FORM'

export const types = {
  LOAD_ELEMENTS,
  LOAD_DICTIONARIES,
  LOAD_TEMPLATES,
  LOAD_TEMPLATES_REQUEST,
  LOAD_TEMPLATES_FAILURE,
  LOAD_TEMPLATE,
  LOAD_TEMPLATE_REQUEST,
  LOAD_INSTALLED_ELEMENTS,
  LOAD_INSTALLED_ELEMENTS_REQUEST,
  LOAD_INSTALLED_ELEMENTS_FAILURE,
  LOAD_INSTALLED_ELEMENT,
  LOAD_INSTALLED_ELEMENT_REQUEST,
  LOAD_INSTALLED_ELEMENT_FAILURE,
  LOAD_TEMPLATE_FAILURE,
  SELECT_DEPARTMENT,
  SELECT_ELEMENT_GROUP,
  SELECT_ELEMENT_SUB_GROUP,
  SEARCH_TEMPLATE,
  ADD_ELEMENT,
  UPDATE_ELEMENT,
  EDIT_ELEMENT,
  REMOVE_ELEMENT,
  CLEAR_FORM,
}

export const loadElements = (elements) => ({
  type: LOAD_ELEMENTS,
  elements,
})
export const loadDictionaries = (dictionaries) => ({
  type: LOAD_DICTIONARIES,
  dictionaries,
})
export const loadTemplates = (filterParams) => ({
  type: LOAD_TEMPLATES,
  request: {
    url: '/elements_order_templates.json',
    method: 'get',
    body: filterParams,
  },
})

export const loadInstalledElements = (locationId, filterParams) => ({
  type: LOAD_INSTALLED_ELEMENTS,
  locationId,
  request: {
    url: `/locations/${locationId}/installed_elements.json`,
    method: 'get',
    body: filterParams,
  },
})

export const loadTemplate = (templateId) => ({
  type: LOAD_TEMPLATE,
  templateId,
  request: {
    url: `/elements_order_templates/${templateId}.json?fetch_default_relations=true`,
    method: 'get',
  },
})

export const loadInstalledElement = (locationId, elementId) => ({
  type: LOAD_INSTALLED_ELEMENT,
  elementId,
  request: {
    url: `/locations/${locationId}/installed_element.json?element_id=${elementId}`,
    method: 'get',
  },
})

export const selectElementGroup = (elementGroupId) => ({
  type: SELECT_ELEMENT_GROUP,
  elementGroupId,
})
export const selectElementSubGroup = (elementSubGroupId) => ({
  type: SELECT_ELEMENT_SUB_GROUP,
  elementSubGroupId,
})
export const selectDepartment = (departmentId) => ({
  type: SELECT_DEPARTMENT,
  departmentId,
})
export const searchTemplate = (searchTerm) => ({
  type: SEARCH_TEMPLATE,
  searchTerm,
})
export const addElement = (element, key) => ({
  type: ADD_ELEMENT,
  element,
  key,
})
export const updateElement = (finderKey, finderValue, field, value) => ({
  type: UPDATE_ELEMENT,
  finderKey,
  finderValue,
  field,
  value,
})
export const editElement = (element) => ({
  type: EDIT_ELEMENT,
  element,
})
export const removeElement = (key, id) => ({
  type: REMOVE_ELEMENT,
  key,
  id,
})
export const clearForm = () => ({ type: CLEAR_FORM })
