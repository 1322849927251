const SELECT_TEMPLATE_FIELD  = 'surveys_element/SELECT_TEMPLATE_FIELD'
const SELECT_TEMPLATE        = 'surveys_element/SELECT_TEMPLATE'
const LOAD_TEMPLATES         = 'surveys_element/LOAD_TEMPLATES'
const LOAD_FIELDS            = 'surveys_element/LOAD_FIELDS'
const LOAD_SIBLINGS          = 'surveys_element/LOAD_SIBLINGS'
const CHANGE_INPUT_VALUE     = 'surveys_element/CHANGE_INPUT_VALUE'
const ADD_FIELD              = 'surveys_element/ADD_FIELD'
const REMOVE_FIELD           = 'surveys_element/REMOVE_FIELD'
const CHANGE_POSITION        = 'surveys_element/CHANGE_POSITION'
const ADD_TEMPLATE_FIELD     = 'surveys_element/ADD_TEMPLATE_FIELD'
const REMOVE_TEMPLATE_FIELD  = 'surveys_element/REMOVE_TEMPLATE_FIELD'
const LOAD_ASSOCIATIONS      = 'surveys_element/LOAD_ASSOCIATIONS'
const LOAD_LOCATION_ELEMENT_ASSOCIATIONS = 'surveys_element/LOAD_LOCATION_ELEMENT_ASSOCIATIONS'
const SELECT_DEFAULT_ASSOCIATION = 'surveys_element/SELECT_DEFAULT_ASSOCIATION'

export const types = {
  SELECT_TEMPLATE_FIELD, SELECT_TEMPLATE, LOAD_TEMPLATES,
  ADD_TEMPLATE_FIELD, REMOVE_TEMPLATE_FIELD, LOAD_ASSOCIATIONS,
  LOAD_LOCATION_ELEMENT_ASSOCIATIONS, LOAD_FIELDS, LOAD_SIBLINGS, CHANGE_INPUT_VALUE,
  ADD_FIELD, REMOVE_FIELD, CHANGE_POSITION, SELECT_DEFAULT_ASSOCIATION,
}

export const selectTemplateField = (id, field) => ({ type: SELECT_TEMPLATE_FIELD, id, field })
export const selectTemplate      = templateId => ({ type: SELECT_TEMPLATE, templateId })
export const loadTemplates       = templates => ({ type: LOAD_TEMPLATES, templates })
export const addTemplateField    = (id, name, value) => ({ type: ADD_TEMPLATE_FIELD, id, name, value })
export const removeTemplateField = id => ({ type: REMOVE_TEMPLATE_FIELD, id })
export const loadAssociations    = (url, params, id) => ({
  type: LOAD_ASSOCIATIONS,
  request: {
    url,
    method: 'get',
    body: { ...params },
    // body: { params: { template_id: templateId, field: field } },
    id
  },
})
export const loadLocationElementAssociations = (url, params, id) => ({
  type: LOAD_LOCATION_ELEMENT_ASSOCIATIONS,
  request: {
    url,
    method: 'get',
    body: { ...params },
    id,
  },
})
export const loadFields = (fields) => ({ type: LOAD_FIELDS, fields })
export const loadSiblings = siblings => ({ type: LOAD_SIBLINGS, siblings })
export const changeInputValue = (position, name, value) => ({ type: CHANGE_INPUT_VALUE, position, name, value })
export const addField = params => ({ type: ADD_FIELD, params })
export const removeField = position => ({ type: REMOVE_FIELD, position })
export const changePosition = (startPosition, endPosition) => ({ type: CHANGE_POSITION, startPosition, endPosition })
export const selectDefaultAssociation = (id, value) => ({ type: SELECT_DEFAULT_ASSOCIATION, id, value })
