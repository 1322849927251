const $ = window.$

// action should contain modal data
//
// e.g.
// {
//   type: ...,
//   modal: {
//     id: 'attach-photo-to-element',
//     open: true, # or close: true
//    }
//  }
const apiMiddleware = store => next => action => {
  if (!action.modal) { return next(action) }

  const response = next(action)
  $(`#${action.modal.id}`).modal(action.modal.open ? 'show' : 'hide')
  return response
}

export default apiMiddleware
