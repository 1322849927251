import React from 'react'
import { Creatable as Select } from 'components/shared/base_react_select'
import store from 'stores/attachments_categories_store'

class AttachmentCategory extends React.Component {

  constructor (props) {
    super(props)
    this.state = { value: props.value }
    this.listener = store.addListener('OptionListUpdated', () => this.forceUpdate())
    store.init(props.url)
  }

  getOptions () {
    return store.options || []
  }

  onChange (optData) {
    let newStateData = { }
    if (optData) newStateData = { value: optData.value }
    else newStateData = { value: '' }
    this.setState(newStateData)
    this.props.onChange(newStateData)
  }

  onCreateOption (option) {
    store.create(option).then((resp_data) => {
      this.onChange({ value: resp_data.category.value })
    })
  }

  componentDidMount () {
    this.props.onChange(this.state)
  }

  componentWillUnmount () {
    this.listener.remove()
  }

  render () {
    const options = this.getOptions()
    return (
      <div className="attachments-category">
        <Select
          classNamePrefix="react-select"
          options={options}
          value={options.find(o => o.value === this.state.value)}
          onChange={ (e) => this.onChange(e) }
          onCreateOption={ (e) => this.onCreateOption(e) }
          placeholder={I18n.t('activerecord.models.attachments/category.one')} />
      </div>
    )
  }
}

export default AttachmentCategory
