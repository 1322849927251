import React, { useState } from 'react'
import ajax from '../../../libs/init_ajax'

const INTERVAL_OF_CHECKING = 5000

export default function DownloadAllAttachments(props) {
  const [ready, setReady] = useState(props.ready)
  const [href, setHref] = useState(props.href)
  const [isLoading, setIsLoading] = useState(false)
  const [timerID, setTimerID] = useState(null)

  const handleResponse = (resp) => {
    const { data } = resp
    if (data.ready) {
      setReady(data.ready)
      setHref(data.href)
      setIsLoading(false)
    } else {
      if (timerID) {
        clearTimeout(timerID)
      }
      setTimerID(setTimeout(askReady, INTERVAL_OF_CHECKING))
    }
  }

  const onClick = (e) => {
    if (!ready) {
      e.preventDefault()
      setIsLoading(true)
      ajax({
        url: props.buildUrl,
        method: 'patch',
        data: {},
      }).then(handleResponse)
    }
    // else just follow the link - it should have a download URL
  }

  const askReady = () => {
    ajax({
      url: props.readyUrl,
      method: 'get',
    }).then(handleResponse)
  }

  return (
    <div className="download-all-attachments">
      {isLoading && (
        <>
          {props.trans.waitMessage}
          <i className="fa fa-spinner fa-spin" />
        </>
      )}
      {!isLoading && (
        <a href={href} onClick={(e) => onClick(e)} className={props.downloadLnkClass}>
          {props.trans.downloadAllCaption}
        </a>
      )}
    </div>
  )
}
