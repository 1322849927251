import { fromJS } from 'immutable'
import { types } from 'actions/projects/send_links_button_actions'

function reducer (state, action) {
  switch (action.type) {
    case types.LOAD_PROJECTS:
      return state.set('availableProjects', fromJS(action.data))
  }
  return state
}

export default reducer
