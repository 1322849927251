declare global {
  interface Window {
    AdobeDC: any
  }
}

interface ViewerConfig {
  embedMode: string
  enableFormFilling?: boolean
  showDownloadPDF?: boolean
  showPrintPDF?: boolean
  showZoomControl?: boolean
  enableAnnotationAPIs?: boolean
  showAnnotationTools?: boolean
  showCommentsPanel?: boolean
  showToolbar?: boolean
  showToolsOnTextSelection?: boolean
  downloadWithAnnotations?: boolean
  printWithAnnotations?: boolean
  showLeftHandPanel?: boolean
  showPageControls?: boolean
  includePDFAnnotations?: boolean
}

interface FileLocation {
  url: string
  headers?: Array<{ key: string; value: string }>
}

interface FileMetadata {
  fileName: string
  id?: string
}

interface ContentConfig {
  location?: FileLocation
  promise?: Promise<ArrayBuffer>
}

interface PreviewFileOptions {
  content: ContentConfig
  metaData: FileMetadata
}

interface SaveApiResponse {
  code: string
  data: {
    metaData: any
  }
}

class ViewSDKClient {
  private readyPromise: Promise<void>
  private adobeDCView: any

  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve()
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', () => resolve())
      }
    })
    this.adobeDCView = undefined
  }

  ready(): Promise<void> {
    return this.readyPromise
  }

  previewFile(
    viewerConfig: ViewerConfig,
    previewFileOptions: PreviewFileOptions,
    clientId: string,
    divId?: string
  ): Promise<void> {
    const config: { divId?: string; clientId: string } = {
      clientId: clientId,
    }

    if (divId) {
      config.divId = divId
    }

    this.adobeDCView = new window.AdobeDC.View(config)

    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url: previewFileOptions.content.location.url,
          },
        },
        metaData: {
          fileName: previewFileOptions.metaData.fileName,
          id: previewFileOptions.metaData.id,
        },
      },
      viewerConfig
    )
    return previewFilePromise
  }

  registerSaveApiHandler(): void {
    const saveApiHandler = (metaData: any, content: any, options: any): Promise<SaveApiResponse> => {
      const uint8Array = new Uint8Array(content)
      const fileBlob = new Blob([uint8Array], { type: 'application/pdf' })
      const formData = new FormData()

      formData.append('attachment[file]', fileBlob, metaData.fileName)

      return fetch(`/attachments/${metaData.id}`, {
        method: 'PATCH',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => ({
          code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
          data: { metaData: data },
        }))
        .catch((error) => ({
          code: window.AdobeDC.View.Enum.ApiResponseCode.FAIL,
          data: { metaData: error },
        }))
    }

    this.adobeDCView.registerCallback(window.AdobeDC.View.Enum.CallbackType.SAVE_API, saveApiHandler, {})
  }

  registerGetUserProfileApiHandler(userName): void {
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
      (event: any) => {
        return new Promise((resolve, reject) => {
          resolve({
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              userProfile: {
                name: userName,
              },
            },
          })
        })
      },
      {
        enablePDFAnalytics: true,
      }
    )
  }
}

export default ViewSDKClient
