import { types } from 'actions/overview_actions'
import { fromJS } from 'immutable'
import OverviewLocalStore from 'local_storages/overview'

export default function reducer (state = {}, action) {
  switch (action.type) {
    case types.INIT:
      return state.merge(fromJS(action.data))
    case types.SELECT:
      return state
        .setIn(['projectGroups'], state.get('projectGroups').concat(fromJS(action.data.projectGroups || [])))
        .setIn(['mspWorkflows'], state.get('mspWorkflows').concat(fromJS(action.data.mspWorkflows || [])))
        .setIn(['comments'], fromJS(action.data.comments))
        .setIn(['statusChanges'], fromJS(action.data.statusChanges))
    case types.GET_PROJECTS_REQUEST:
      return state.set('projects', fromJS([]))
    case types.GET_PROJECTS:
      return state.set('projects', fromJS(action.data.projects))
  }
  return state
}
