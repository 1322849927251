const attributesFor = (file) => {
  let attributes = []
  if (!file._new) { attributes.push('id') }
  if (file._destroy) { attributes.push('_destroy') }
  else {
    if (file._new) { attributes.push('src') }
    if (file.title) { attributes.push('title') }
    if (file.description) { attributes.push('description') }
    if (file.position) { attributes.push('position') }
    attributes.push('category_id')
  }
  return attributes
}

const filesToSend = (file) => {
  if (file._new) { return !file._destroy && !file._clientUploading }
  return file._dirty || file._destroy || file._updated
}

const inputKey  = (file, attribute) => `${file.id}-${attribute}`

const inputName = (index, attribute, modelData, isNew = false) => {
  if (attribute === 'src' && isNew) { attribute = 'temp_file' }  // only for new
  return `${modelData.name}[${modelData.attribute}_attributes][${index}][${attribute}]`
}

const HiddenInput = ({index, file, attribute, modelData}) => {
  return (
    <input
      type="hidden"
      name={inputName(index, attribute, modelData, file._new)}
      value={file[attribute] || ''}
    />
  )
}

const FileInput = ({ file, index, modelData }) => {
  return attributesFor(file).map(attribute => (
    <HiddenInput key={`hidden-input-${inputKey(file, attribute)}`} file={file} index={index} attribute={attribute} modelData={modelData} />
  ))
}

export default ({ files, modelData, enabled }) => {
  if (!enabled) { return null }

  return (
    <div>
      {files.filter(filesToSend).map((file, index) => (
        <FileInput key={`file-input-${index}-${file.id}`} file={file} index={index} modelData={modelData} />
      ))}
    </div>
  )
}
