const MODAL_STYLES = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.6)',
  },
  content: {
    top: "45%",
    bottom: 0,
    left: 0,
    right: 0,
    border: "none",
    backgroundColor: "transparent",
    textAlign: "center",
    color: "white",
  },
}

export default MODAL_STYLES
