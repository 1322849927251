import { types } from 'actions/report'
import _ from 'underscore'
import { fromJS } from 'immutable'
import conditions from '../shared/conditions'

export default function (state, action) {
  if (action.type === types.LOAD_REPORT) {
    return _.size(action.report.conditions) ? fromJS(action.report.conditions) : fromJS({ type: 'group' })
  }
  return action.field === 'conditions' ? conditions(state, action) : state
}
