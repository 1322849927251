import snakeCaseKeys from 'snakecase-keys'

const INIT                        = 'campaigns/create_postorder/INIT'
const CREATE_POSTORDER_WITH_ITEMS = 'campaigns/create_postorder/CREATE_POSTORDER_WITH_ITEMS'
const UPDATE_ITEM                 = 'campaigns/create_postorder/UPDATE_ITEM'
const GET_ITEMS                   = 'campaigns/create_postorder/GET_ITEMS'

export const types = { INIT, UPDATE_ITEM, GET_ITEMS, CREATE_POSTORDER_WITH_ITEMS }

export const initBaseState = (data) => ({ type: INIT, ...data })

export const updateItem = (campaignElementId, data) => ({ type: UPDATE_ITEM, campaignElementId, data })

export const getItems = (campaignId, locationId) => ({
  type: GET_ITEMS,
  request: {
    url: `/campaigns/${campaignId}/items-for-init-postorder`,
    method: 'get',
    body: {
      format: 'json',
      location_id: locationId
    }
  }
})

export const createPostorderWithItems = (campaignId, locationId, items) => ({
  type: CREATE_POSTORDER_WITH_ITEMS,
  request: {
    url: `/campaigns/${campaignId}/postorders`,
    method: 'post',
    body: {
      format: 'json',
      postorder: {
        campaign_id: campaignId,
        location_id: locationId,
        items_attributes: snakeCaseKeys(items.toJS())
      }
    },
    redirectTo: true
  }
})
