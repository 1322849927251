import { types } from 'actions/elements_groups_enabled_columns'
import { fromJS } from 'immutable'

export default function reducer (state = {}, action) {
  const selectedColumnsPath = ['selectedColumns']
  switch (action.type) {
    case types.LOAD_SELECTED:
      return state.setIn(selectedColumnsPath, fromJS(action.names))
    case types.DROP_TO_SELECTED:
      let selectedColumns = state.getIn(selectedColumnsPath)
      return state.setIn(
        selectedColumnsPath,
        insertIntoPosition(action.name, action.position, selectedColumns)
      )
    case types.DROP_TO_AVAILABLE:
      return state.updateIn(selectedColumnsPath,
        selected => selected.filter( (item) => item !== action.name ))
  }
  return state
}

function insertIntoPosition(item, position, collection) {
  if (position === undefined) {
    position = collection.size
  }

  if (collection.includes(item)) {
    collection = collection.delete(collection.indexOf(item))
  }

  return collection.insert(position, item)
}
