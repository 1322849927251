import { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap';

const I18n = window.I18n

const SimpleConfirmation = ({ show, title, message, confirmationHandler, handlerArgs, actionComponent, actionProps }) => {

  const [isOpen, changeWindowState] = useState(show)

  useEffect(() => changeWindowState(show), [show])

  const toggle         = () => changeWindowState(prev => !prev)
  const onClickCancel  = () => changeWindowState(false)
  const onClickConfirm = () => confirmationHandler(...handlerArgs) && changeWindowState(false)

  const ActionComponent = actionComponent

  if (!isOpen) { return <ActionComponent {...actionProps} onClick={toggle} /> }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClickCancel}>{I18n.t('application.actions.cancel')}</Button>
        <Button color="primary" onClick={onClickConfirm}>{I18n.t('application.actions.ok')}</Button>{' '}
      </ModalFooter>
    </Modal>
  )
}

SimpleConfirmation.defaultProps = {
  show: false,
  title: I18n.t('application.confirmation_window.title'),
  message: I18n.t('application.confirmation_window.body'),
  handlerArgs: [],
  actionProps: {}
}


export default SimpleConfirmation
