import { types } from 'actions/project_actions'

export default function reducer (state = {}, action, { links }) {
  switch (action.type) {
    case types.LOAD_PROJECT:
      return state.set('loaded', true)
    case types.OPEN_IMAGES_MODAL:
      return state.set('reservedLinks', links)
    case types.RESET_LINKS:
      return state.set('reservedLinks', null)
    case types.SAVE_LINKS:
      return state.set('reservedLinks', null)
  }
  return state
}
