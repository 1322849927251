const TOGGLE_DIALOG = 'sharing_links/TOGGLE_DIALOG'
const LOAD_SHARING_LINKS = 'sharing_links/LOAD_SHARING_LINKS'
const SAVE_SHARING_LINK = 'sharing_links/SAVE_SHARING_LINK'
const DELETE_SHARING_LINK = 'sharing_links/DELETE_SHARING_LINK'

export const types = {
  TOGGLE_DIALOG,
  LOAD_SHARING_LINKS,
  SAVE_SHARING_LINK,
  DELETE_SHARING_LINK,
}

export const toggleDialog = (opened, currentLink) => ({
  type: TOGGLE_DIALOG,
  opened,
  currentLink,
})

export const loadSharingLinks = (links) => ({
  type: LOAD_SHARING_LINKS,
  links,
})

export const saveSharingLink = (url, payload) => ({
  type: SAVE_SHARING_LINK,
  request: {
    url,
    format: 'json',
    method: 'post',
    body: { ...payload },
  },
})

export const deleteSharingLink = (id) => ({
  type: DELETE_SHARING_LINK,
  request: {
    url: `/sharing_links/${id}.json`,
    format: 'json',
    method: 'delete',
  },
})
