import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import AlbumsComponents from './albums'
import AlbumUploader from './albums/uploader'
import FileShape from '../file_shape'
import DownloadAllAttachments from '../../shared/download_all_attachments'

const { Gallery, AlbumName } = AlbumsComponents

const Album = (props) => {
  const {
    albumData,
    files,
    buttonId,
    dropElementId,
    formAction,
    fileActions,
    isReqStateInProgress,
    downloadAllOptions,
  } = props
  const getCSRF = () => ({
    name: $('meta[name=csrf-param]').attr('content'),
    value: $('meta[name=csrf-token]').attr('content'),
  })

  const makePhotoFavorite = useCallback((id, favorite) => {
    fileActions.makeFavorite(buttonId, id, favorite)
  }, [buttonId])

  return (
    <div className="album">
      <AlbumName {...albumData} />
      { downloadAllOptions && <DownloadAllAttachments {...downloadAllOptions} /> }
      <Gallery photos={files} albumTitle={albumData.title} makePhotoFavorite={makePhotoFavorite} isReqStateInProgress={isReqStateInProgress}>
        <AlbumUploader
          csrfData={getCSRF()}
          buttonId={buttonId}
          dropElementId={dropElementId}
          isUploading={isReqStateInProgress}
          formAction={formAction}
        />
      </Gallery>
    </div>
  )
}

Album.propTypes = {
  files: PropTypes.arrayOf(FileShape).isRequired,
  buttonId: PropTypes.string.isRequired,
  dropElementId: PropTypes.string.isRequired,
  isReqStateInProgres: PropTypes.bool,
}

export default Album
