const INIT_FIELDS    = 'admin_template_fields/INIT_FIELDS'
const ADD_FIELDS     = 'admin_template_fields/ADD_FIELD'
const REMOVE_FIELD   = 'admin_template_fields/REMOVE_FIELD'
const MOVE_FIELD   = 'admin_template_fields/MOVE_FIELD'
const CHANGE_FIELD_DATA = 'admin_template_fields/CHANGE_FIELD_DATA'

export const types = { INIT_FIELDS, ADD_FIELDS, REMOVE_FIELD, CHANGE_FIELD_DATA, MOVE_FIELD }

export const initFields = (data) => ({ type: INIT_FIELDS, ...data })

export const addFields = (fieldNames) => ({ type: ADD_FIELDS, fieldNames })

export const removeField = (fieldName) => ({ type: REMOVE_FIELD, fieldName })

export const changeFieldData = (fieldName, attrKey, value) => ({ type: CHANGE_FIELD_DATA, fieldName, attrKey, value })

export const moveField = (fieldName, position) => ({ type: MOVE_FIELD, fieldName, position })
