import React from 'react'

const ATTRIBUTES_FOR_ICON = ['documents']

export default ({ attribute, file }) => {
  if (ATTRIBUTES_FOR_ICON.includes(attribute)) {
    return <span className="fa fa-picture-o" />
  }

  const { src, name } = file

  return (
    <video className="video-js" controls data-setup="{}" width="250" height="150">
      {src && src.includes('.mov') ? <source src={src} type="video/mp4" /> : <source src={src} type="video/mp4" />}
    </video>
  )
}
