import Photo from './photo'
import { makeFavorite } from '../../../../actions/uploader_actions'

const Gallery = ({ photos, albumTitle, children, makePhotoFavorite }) => {

  return (
    <div className="gallery">
      { photos.map((photo, index) => <Photo
          key={photo.id}
          {...photo}
          albumTitle={albumTitle}
          makeFavorite={makePhotoFavorite} />
        )
      }
      {children}
    </div>
  )
}

export default Gallery
